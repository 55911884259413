import { Component, OnInit, Input } from '@angular/core';
import { GHDCInfo, FieldComparationOption, FieldComparationStyle } from '../interfaces/gh-dcinfo';
import { GHServiceService } from '../services/gh-service.service';
import { CommonInfoFolder } from '../../Interfaces/commonInfoFolder';

@Component({
  selector: 'app-gh-production-view',
  templateUrl: './gh-production-view.component.html',
  styleUrls: ['./gh-production-view.component.css']
})

export class GHProductionViewComponent implements OnInit {

  @Input('startDateTime') startDateTime: Date;
  @Input('commonInfo') commonInfo: CommonInfoFolder;

  existChild: boolean = true;
  userNamesFromDataEntries: Array<string> = new Array<string>();
  titleForDataEntry: Array<string> = new Array<string>();
  dataEntries: Array<GHDCInfo> = new Array<GHDCInfo>();
  dataEntryStyle: FieldComparationStyle = {
    documentType: FieldComparationOption.EQUAL,
    countryOfTreatment: FieldComparationOption.EQUAL,
    invoiceCurrency: FieldComparationOption.EQUAL,
    insuredPerson: FieldComparationOption.EQUAL,
    invoiceNumber: FieldComparationOption.EQUAL,
    invoiceAmount: FieldComparationOption.EQUAL,
    invoiceDate: FieldComparationOption.EQUAL,
    treatmentDateFrom: FieldComparationOption.EQUAL,
    treatmentDateTo: FieldComparationOption.EQUAL,
    providerName: FieldComparationOption.EQUAL,
    providerCity: FieldComparationOption.EQUAL,
    providerStreet: FieldComparationOption.EQUAL,
    providerPostalCode: FieldComparationOption.EQUAL,
    providerHouseNo: FieldComparationOption.EQUAL,
    providerCountry: FieldComparationOption.EQUAL,
    providerCategory: FieldComparationOption.EQUAL,
    diagnosis1: FieldComparationOption.EQUAL,
    diagnosis2: FieldComparationOption.EQUAL,
    diagnosis3: FieldComparationOption.EQUAL,
    quantity: FieldComparationOption.EQUAL,
    documentQuality: FieldComparationOption.EQUAL
  }

  constructor(private ghServices: GHServiceService) { }

  ngOnInit() {
    var toolbarHeight = (document.getElementsByClassName("mat-toolbar-row").item(0) as HTMLElement).offsetHeight;
    var heightWindow = document.documentElement.clientHeight - toolbarHeight + "px";
    console.log(heightWindow);
    document.getElementById("viewportDE").style.height = heightWindow;

    if (document.getElementById("verticalDivider")) {
      document.getElementById("verticalDivider").style.height = document.getElementById("containerForm").style.height;
    }

    this.getDataEntries();
  }

  async getDataEntries(): Promise<void> {
    var dataEntriesValues: any = await this.ghServices.ObtainDEModel(this.commonInfo.folderHash, this.commonInfo.project, this.commonInfo.tagId, true);

    if (dataEntriesValues.length > 0) {
      var firstIndex: number = (dataEntriesValues.length - 2 >= 0) ? dataEntriesValues.length - 2 : 0;
      for (var i = firstIndex; i < dataEntriesValues.length; i++) {
        this.userNamesFromDataEntries.push(dataEntriesValues[i].username);
        this.dataEntries.push(dataEntriesValues[i]);
      }

      if (dataEntriesValues.length >= 3) {
        this.titleForDataEntry.push("Data conflict");
        this.titleForDataEntry.push("Customer review");
      } else if (dataEntriesValues.length == 2) {
        this.titleForDataEntry.push("Data entry");
        this.titleForDataEntry.push("Data conflict");
      } else if (dataEntriesValues.length == 1) {
        this.titleForDataEntry.push("Data entry");
      }

      for (var i = 0; i < this.dataEntries.length; i++) {
        Object.keys(this.dataEntries[i]).forEach( (field) => {
          if (this.dataEntries[i][field] == null) {
            this.dataEntries[i][field] = " ";
          } else if (field != 'origin') {
            this.dataEntries[i][field] = this.dataEntries[i][field].toString();
          }
        });
      }

      this.compareAllDataEntriesFields();

    }
    else{
      this.existChild = false;
    }

    console.debug("DATA ENTRIES: ", this.dataEntries);

  }

  compareAllDataEntriesFields(): void {
    if (this.dataEntries.length > 1) {
      for (var i = 0; i < this.dataEntries.length - 1; i++) {
        var dataEntry: GHDCInfo = this.dataEntries[i];
        var dataConflict: GHDCInfo = this.dataEntries[i+1];
        this.dataEntryStyle = this.compareDataEntries(dataEntry, dataConflict);
      }
    }
  }

  compareDataEntries(dataEntry: GHDCInfo, dataConflict: GHDCInfo): FieldComparationStyle {
    var fieldsStyle: FieldComparationStyle = this.dataEntryStyle;

    if (fieldsStyle.documentType != FieldComparationOption.DIFFERENT) {
      fieldsStyle.documentType = (dataEntry.documentType == dataConflict.documentType) ? FieldComparationOption.EQUAL : FieldComparationOption.DIFFERENT;
    }

    if (fieldsStyle.countryOfTreatment != FieldComparationOption.DIFFERENT) {
      fieldsStyle.countryOfTreatment = (dataEntry.countryOfTreatment == dataConflict.countryOfTreatment) ? FieldComparationOption.EQUAL : FieldComparationOption.DIFFERENT;
    }

    if (fieldsStyle.invoiceCurrency != FieldComparationOption.DIFFERENT) {
      fieldsStyle.invoiceCurrency = (dataEntry.invoiceCurrency == dataConflict.invoiceCurrency) ? FieldComparationOption.EQUAL : FieldComparationOption.DIFFERENT;
    }

    if (fieldsStyle.insuredPerson != FieldComparationOption.DIFFERENT) {
      fieldsStyle.insuredPerson = (dataEntry.insuredPerson == dataConflict.insuredPerson) ? FieldComparationOption.EQUAL : FieldComparationOption.DIFFERENT;
    }

    if (fieldsStyle.invoiceNumber != FieldComparationOption.DIFFERENT) {
      fieldsStyle.invoiceNumber = (dataEntry.invoiceNumber == dataConflict.invoiceNumber) ? FieldComparationOption.EQUAL : FieldComparationOption.DIFFERENT;
    }

    if (fieldsStyle.invoiceAmount != FieldComparationOption.DIFFERENT) {
      fieldsStyle.invoiceAmount = (dataEntry.invoiceAmount == dataConflict.invoiceAmount) ? FieldComparationOption.EQUAL : FieldComparationOption.DIFFERENT;
    }

    if (fieldsStyle.invoiceDate != FieldComparationOption.DIFFERENT) {
      fieldsStyle.invoiceDate = (dataEntry.invoiceDate == dataConflict.invoiceDate) ? FieldComparationOption.EQUAL : FieldComparationOption.DIFFERENT;
    }

    if (fieldsStyle.treatmentDateFrom != FieldComparationOption.DIFFERENT) {
      fieldsStyle.treatmentDateFrom = (dataEntry.treatmentDateFrom == dataConflict.treatmentDateFrom) ? FieldComparationOption.EQUAL : FieldComparationOption.DIFFERENT;
    }

    if (fieldsStyle.treatmentDateTo != FieldComparationOption.DIFFERENT) {
      fieldsStyle.treatmentDateTo = (dataEntry.treatmentDateTo == dataConflict.treatmentDateTo) ? FieldComparationOption.EQUAL : FieldComparationOption.DIFFERENT;
    }

    if (fieldsStyle.providerName != FieldComparationOption.DIFFERENT) {
      fieldsStyle.providerName = (dataEntry.providerName == dataConflict.providerName) ? FieldComparationOption.EQUAL : FieldComparationOption.DIFFERENT;
    }

    if (fieldsStyle.providerCity != FieldComparationOption.DIFFERENT) {
      fieldsStyle.providerCity = (dataEntry.providerCity == dataConflict.providerCity) ? FieldComparationOption.EQUAL : FieldComparationOption.DIFFERENT;
    }

    if (fieldsStyle.providerStreet != FieldComparationOption.DIFFERENT) {
      fieldsStyle.providerStreet = (dataEntry.providerStreet == dataConflict.providerStreet) ? FieldComparationOption.EQUAL : FieldComparationOption.DIFFERENT;
    }

    if (fieldsStyle.providerPostalCode != FieldComparationOption.DIFFERENT) {
      fieldsStyle.providerPostalCode = (dataEntry.providerPostalCode == dataConflict.providerPostalCode) ? FieldComparationOption.EQUAL : FieldComparationOption.DIFFERENT;
    }

    if (fieldsStyle.providerHouseNo != FieldComparationOption.DIFFERENT) {
      fieldsStyle.providerHouseNo = (dataEntry.providerHouseNo == dataConflict.providerHouseNo) ? FieldComparationOption.EQUAL : FieldComparationOption.DIFFERENT;
    }

    if (fieldsStyle.providerCountry != FieldComparationOption.DIFFERENT) {
      fieldsStyle.providerCountry = (dataEntry.providerCountry == dataConflict.providerCountry) ? FieldComparationOption.EQUAL : FieldComparationOption.DIFFERENT;
    }

    if (fieldsStyle.providerCategory != FieldComparationOption.DIFFERENT) {
      fieldsStyle.providerCategory = (dataEntry.providerCategory == dataConflict.providerCategory) ? FieldComparationOption.EQUAL : FieldComparationOption.DIFFERENT;
    }

    if (fieldsStyle.diagnosis1 != FieldComparationOption.DIFFERENT) {
      fieldsStyle.diagnosis1 = (dataEntry.diagnosis1 == dataConflict.diagnosis1) ? FieldComparationOption.EQUAL : FieldComparationOption.DIFFERENT;
    }

    if (fieldsStyle.diagnosis2 != FieldComparationOption.DIFFERENT) {
      fieldsStyle.diagnosis2 = (dataEntry.diagnosis2 == dataConflict.diagnosis2) ? FieldComparationOption.EQUAL : FieldComparationOption.DIFFERENT;
    }

    if (fieldsStyle.diagnosis3 != FieldComparationOption.DIFFERENT) {
      fieldsStyle.diagnosis3 = (dataEntry.diagnosis3 == dataConflict.diagnosis3) ? FieldComparationOption.EQUAL : FieldComparationOption.DIFFERENT;
    }

    if (fieldsStyle.quantity != FieldComparationOption.DIFFERENT) {
      fieldsStyle.quantity = (dataEntry.quantity == dataConflict.quantity) ? FieldComparationOption.EQUAL : FieldComparationOption.DIFFERENT;
    }

    return fieldsStyle;
  }

}
