<cdk-virtual-scroll-viewport itemSize="50" id="viewportDE">
  <div fxFlex="{{isDc(child.key, true)}}" id="containerForm" *ngFor="let child of nchild">
    <div fxFlex="{{isDc(child.key, false)}}">
      <p *ngIf="child.key == 'de1'"> Data Entry 1 : {{dataEntries[0].dataEntryValue.username}}</p>
      <p *ngIf="child.key == 'de2'"> Data Entry 2 : {{dataEntries[1].dataEntryValue.username}}</p>
      <p *ngIf="child.key == 'dc'" > Data Conflict </p>
      <app-renta-data-entry-viewer [dataEntryInfo]="child" [startDateTime]="startDateTime"> </app-renta-data-entry-viewer>
    </div>
    <mat-divider fxFlex="3" vertical inset *ngIf="child.key != 'dc'" id="verticalDivider"></mat-divider>
  </div>
</cdk-virtual-scroll-viewport>
