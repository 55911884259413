import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Dictionary } from '../Interfaces/dictionary';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DataEntryControllerService } from '../services/data-entry-controller.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-renta-data-entry-search-modal',
  templateUrl: './renta-data-entry-search-modal.component.html',
  styleUrls: ['./renta-data-entry-search-modal.component.css']
})
export class RentaDataEntrySearchModalComponent implements OnInit {

  countryOption: Array<Dictionary>;
  filteredOptions: Observable<Array<Dictionary>>;
  folderHash: string;

  Requester = new FormGroup({
    RequesterType: new FormControl(),
    RequesterName: new FormControl(),
    RequesterAddress: new FormControl(),
    RequesterZipCode: new FormControl(),
    RequesterCity: new FormControl(),
    RequesterCountry: new FormControl(),
    RequesterEmail: new FormControl(),
    RequesterPhone: new FormControl(),
    RequesterFax: new FormControl()
  });

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource = ELEMENT_DATA;

  constructor(
    public dialogRef: MatDialogRef<RentaDataEntrySearchModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dataEntryControllerService: DataEntryControllerService,
    private notification: MatSnackBar
  ){ }

  doubleClick(value: PeriodicElement) {
    console.log(value);
    //CHANGE THAT
    this.dialogRef.close(this.Requester.value);
  }
  ngOnInit() {
    this.Requester.get("RequesterType").setValue(this.data);
    var url = new URL(window.location.href);
    this.folderHash = url.searchParams.get("folderHash");

    this.filteredOptions = this.Requester.get('RequesterCountry').valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterSuggestBox(value))
      );

    this.countryOption = new Array<Dictionary>(
      { key: "Afghanistan", value: "AF" },
      { key: "Åland Islands", value: "AX" },
      { key: "Albania", value: "AL" },
      { key: "Algeria", value: "DZ" },
      { key: "American Samoa", value: "AS" },
      { key: "Andorra", value: "AD" },
      { key: "Angola", value: "AO" },
      { key: "Anguilla", value: "AI" },
      { key: "Antarctica", value: "AQ" },
      { key: "Antigua and Barbuda", value: "AG" },
      { key: "Argentina", value: "AR" },
      { key: "Armenia", value: "AM" },
      { key: "Aruba", value: "AW" },
      { key: "Australia", value: "AU" },
      { key: "Austria", value: "AT" },
      { key: "Azerbaijan", value: "AZ" },
      { key: "Bahamas", value: "BS" },
      { key: "Bahrain", value: "BH" },
      { key: "Bangladesh", value: "BD" },
      { key: "Barbados", value: "BB" },
      { key: "Belarus", value: "BY" },
      { key: "Belgium", value: "BE" },
      { key: "Belize", value: "BZ" },
      { key: "Benin", value: "BJ" },
      { key: "Bermuda", value: "BM" },
      { key: "Bhutan", value: "BT" },
      { key: "Bolivia (Plurinational State of)", value: "BO" },
      { key: "Bonaire, Sint Eustatius and Saba", value: "BQ" },
      { key: "Bosnia and Herzegovina", value: "BA" },
      { key: "Botswana", value: "BW" },
      { key: "Bouvet Island", value: "BV" },
      { key: "Brazil", value: "BR" },
      { key: "British Indian Ocean Territory", value: "IO" },
      { key: "Brunei Darussalam", value: "BN" },
      { key: "Bulgaria", value: "BG" },
      { key: "Burkina Faso", value: "BF" },
      { key: "Burundi", value: "BI" },
      { key: "Cabo Verde", value: "CV" },
      { key: "Cambodia", value: "KH" },
      { key: "Cameroon", value: "CM" },
      { key: "Canada", value: "CA" },
      { key: "Cayman Islands", value: "KY" },
      { key: "Central African Republic", value: "CF" },
      { key: "Chad", value: "TD" },
      { key: "Chile", value: "CL" },
      { key: "China", value: "CN" },
      { key: "Christmas Island", value: "CX" },
      { key: "Cocos (Keeling) Islands", value: "CC" },
      { key: "Colombia", value: "CO" },
      { key: "Comoros", value: "KM" },
      { key: "Congo", value: "CG" },
      { key: "Congo (Democratic Republic of the)", value: "CD" },
      { key: "Cook Islands", value: "CK" },
      { key: "Costa Rica", value: "CR" },
      { key: "Côte d'Ivoire", value: "CI" },
      { key: "Croatia", value: "HR" },
      { key: "Cuba", value: "CU" },
      { key: "Curaçao", value: "CW" },
      { key: "Cyprus", value: "CY" },
      { key: "Czechia", value: "CZ" },
      { key: "Denmark", value: "DK" },
      { key: "Djibouti", value: "DJ" },
      { key: "Dominica", value: "DM" },
      { key: "Dominican Republic", value: "DO" },
      { key: "Ecuador", value: "EC" },
      { key: "Egypt", value: "EG" },
      { key: "El Salvador", value: "SV" },
      { key: "Equatorial Guinea", value: "GQ" },
      { key: "Eritrea", value: "ER" },
      { key: "Estonia", value: "EE" },
      { key: "Ethiopia", value: "ET" },
      { key: "Falkland Islands (Malvinas)", value: "FK" },
      { key: "Faroe Islands", value: "FO" },
      { key: "Fiji", value: "FJ" },
      { key: "Finland", value: "FI" },
      { key: "France", value: "FR" },
      { key: "French Guiana", value: "GF" },
      { key: "French Polynesia", value: "PF" },
      { key: "French Southern Territories", value: "TF" },
      { key: "Gabon", value: "GA" },
      { key: "Gambia", value: "GM" },
      { key: "Georgia", value: "GE" },
      { key: "Germany", value: "DE" },
      { key: "Ghana", value: "GH" },
      { key: "Gibraltar", value: "GI" },
      { key: "Greece", value: "GR" },
      { key: "Greenland", value: "GL" },
      { key: "Grenada", value: "GD" },
      { key: "Guadeloupe", value: "GP" },
      { key: "Guam", value: "GU" },
      { key: "Guatemala", value: "GT" },
      { key: "Guernsey", value: "GG" },
      { key: "Guinea", value: "GN" },
      { key: "Guinea-Bissau", value: "GW" },
      { key: "Guyana", value: "GY" },
      { key: "Haiti", value: "HT" },
      { key: "Heard Island and McDonald Islands", value: "HM" },
      { key: "Holy See", value: "VA" },
      { key: "Honduras", value: "HN" },
      { key: "Hong Kong", value: "HK" },
      { key: "Hungary", value: "HU" },
      { key: "Iceland", value: "IS" },
      { key: "India", value: "IN" },
      { key: "Indonesia", value: "ID" },
      { key: "Iran (Islamic Republic of)", value: "IR" },
      { key: "Iraq", value: "IQ" },
      { key: "Ireland", value: "IE" },
      { key: "Isle of Man", value: "IM" },
      { key: "Israel", value: "IL" },
      { key: "Italy", value: "IT" },
      { key: "Jamaica", value: "JM" },
      { key: "Japan", value: "JP" },
      { key: "Jersey", value: "JE" },
      { key: "Jordan", value: "JO" },
      { key: "Kazakhstan", value: "KZ" },
      { key: "Kenya", value: "KE" },
      { key: "Kiribati", value: "KI" },
      { key: "Korea (Democratic People's Republic of)", value: "KP" },
      { key: "Korea (Republic of)", value: "KR" },
      { key: "Kuwait", value: "KW" },
      { key: "Kyrgyzstan", value: "KG" },
      { key: "Lao People's Democratic Republic", value: "LA" },
      { key: "Latvia", value: "LV" },
      { key: "Lebanon", value: "LB" },
      { key: "Lesotho", value: "LS" },
      { key: "Liberia", value: "LR" },
      { key: "Libya", value: "LY" },
      { key: "Liechtenstein", value: "LI" },
      { key: "Lithuania", value: "LT" },
      { key: "Luxembourg", value: "LU" },
      { key: "Macao", value: "MO" },
      { key: "Macedonia (the former Yugoslav Republic of)", value: "MK" },
      { key: "Madagascar", value: "MG" },
      { key: "Malawi", value: "MW" },
      { key: "Malaysia", value: "MY" },
      { key: "Maldives", value: "MV" },
      { key: "Mali", value: "ML" },
      { key: "Malta", value: "MT" },
      { key: "Marshall Islands", value: "MH" },
      { key: "Martinique", value: "MQ" },
      { key: "Mauritania", value: "MR" },
      { key: "Mauritius", value: "MU" },
      { key: "Mayotte", value: "YT" },
      { key: "Mexico", value: "MX" },
      { key: "Micronesia (Federated States of)", value: "FM" },
      { key: "Moldova (Republic of)", value: "MD" },
      { key: "Monaco", value: "MC" },
      { key: "Mongolia", value: "MN" },
      { key: "Montenegro", value: "ME" },
      { key: "Montserrat", value: "MS" },
      { key: "Morocco", value: "MA" },
      { key: "Mozambique", value: "MZ" },
      { key: "Myanmar", value: "MM" },
      { key: "Namibia", value: "NA" },
      { key: "Nauru", value: "NR" },
      { key: "Nepal", value: "NP" },
      { key: "Netherlands", value: "NL" },
      { key: "New Caledonia", value: "NC" },
      { key: "New Zealand", value: "NZ" },
      { key: "Nicaragua", value: "NI" },
      { key: "Niger", value: "NE" },
      { key: "Nigeria", value: "NG" },
      { key: "Niue", value: "NU" },
      { key: "Norfolk Island", value: "NF" },
      { key: "Northern Mariana Islands", value: "MP" },
      { key: "Norway", value: "NO" },
      { key: "Oman", value: "OM" },
      { key: "Pakistan", value: "PK" },
      { key: "Palau", value: "PW" },
      { key: "Palestine, State of", value: "PS" },
      { key: "Panama", value: "PA" },
      { key: "Papua New Guinea", value: "PG" },
      { key: "Paraguay", value: "PY" },
      { key: "Peru", value: "PE" },
      { key: "Philippines", value: "PH" },
      { key: "Pitcairn", value: "PN" },
      { key: "Poland", value: "PL" },
      { key: "Portugal", value: "PT" },
      { key: "Puerto Rico", value: "PR" },
      { key: "Qatar", value: "QA" },
      { key: "Réunion", value: "RE" },
      { key: "Romania", value: "RO" },
      { key: "Russian Federation", value: "RU" },
      { key: "Rwanda", value: "RW" },
      { key: "Saint Barthélemy", value: "BL" },
      { key: "Saint Helena, Ascension and Tristan da Cunha", value: "SH" },
      { key: "Saint Kitts and Nevis", value: "KN" },
      { key: "Saint Lucia", value: "LC" },
      { key: "Saint Martin (French part)", value: "MF" },
      { key: "Saint Pierre and Miquelon", value: "PM" },
      { key: "Saint Vincent and the Grenadines", value: "VC" },
      { key: "Samoa", value: "WS" },
      { key: "San Marino", value: "SM" },
      { key: "Sao Tome and Principe", value: "ST" },
      { key: "Saudi Arabia", value: "SA" },
      { key: "Senegal", value: "SN" },
      { key: "Serbia", value: "RS" },
      { key: "Seychelles", value: "SC" },
      { key: "Sierra Leone", value: "SL" },
      { key: "Singapore", value: "SG" },
      { key: "Sint Maarten (Dutch part)", value: "SX" },
      { key: "Slovakia", value: "SK" },
      { key: "Slovenia", value: "SI" },
      { key: "Solomon Islands", value: "SB" },
      { key: "Somalia", value: "SO" },
      { key: "South Africa", value: "ZA" },
      { key: "South Georgia and the South Sandwich Islands", value: "GS" },
      { key: "South Sudan", value: "SS" },
      { key: "Spain", value: "ES" },
      { key: "Sri Lanka", value: "LK" },
      { key: "Sudan", value: "SD" },
      { key: "Suriname", value: "SR" },
      { key: "Svalbard and Jan Mayen", value: "SJ" },
      { key: "Swaziland", value: "SZ" },
      { key: "Sweden", value: "SE" },
      { key: "Switzerland", value: "CH" },
      { key: "Syrian Arab Republic", value: "SY" },
      { key: "Taiwan, Province of China[a]", value: "TW" },
      { key: "Tajikistan", value: "TJ" },
      { key: "Tanzania, United Republic of", value: "TZ" },
      { key: "Thailand", value: "TH" },
      { key: "Timor-Leste", value: "TL" },
      { key: "Togo", value: "TG" },
      { key: "Tokelau", value: "TK" },
      { key: "Tonga", value: "TO" },
      { key: "Trinidad and Tobago", value: "TT" },
      { key: "Tunisia", value: "TN" },
      { key: "Turkey", value: "TR" },
      { key: "Turkmenistan", value: "TM" },
      { key: "Turks and Caicos Islands", value: "TC" },
      { key: "Tuvalu", value: "TV" },
      { key: "Uganda", value: "UG" },
      { key: "Ukraine", value: "UA" },
      { key: "United Arab Emirates", value: "AE" },
      { key: "United Kingdom of Great Britain and Northern Ireland", value: "GB" },
      { key: "United States of America", value: "US" },
      { key: "United States Minor Outlying Islands", value: "UM" },
      { key: "Uruguay", value: "UY" },
      { key: "Uzbekistan", value: "UZ" },
      { key: "Vanuatu", value: "VU" },
      { key: "Venezuela (Bolivarian Republic of)", value: "VE" },
      { key: "Viet Nam", value: "VN" },
      { key: "Virgin Islands (British)", value: "VG" },
      { key: "Virgin Islands (U.S.)", value: "VI" },
      { key: "Wallis and Futuna", value: "WF" },
      { key: "Western Sahara", value: "EH" },
      { key: "Yemen", value: "YE" },
      { key: "Zambia", value: "ZM" },
      { key: "Zimbabwe", value: "ZW" }
    );
  }

  private filterSuggestBox(value: string): Array<Dictionary> {
    const filterValue = value.toLowerCase();

    return this.countryOption.filter(option => option.key.toLowerCase().includes(filterValue));
  }

  async onSubmit(resultForm) {
    var key = resultForm['RequesterCountry'];
    resultForm['RequesterCountry'] = this.countryOption.find(option => option.key == key).value;

    console.log(resultForm);
    var result = await this.dataEntryControllerService.Search(resultForm, this.folderHash);
    this.notification.open(result, "", {
      duration: 2000,
    });
  }

}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

//ESTO ES TEMPORAL
const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
];
