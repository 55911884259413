<cdk-virtual-scroll-viewport itemSize="50" id="viewportDE">
  <div *ngIf="!existChild">
    <p id="noData"> No data </p>
  </div>
  <div *ngIf="existChild">
    <div fxFlex="33" id="containerForm" *ngFor="let child of nchild">
      <div fxFlex="97">
        <p *ngIf="child.key == 'de1'"> Data Entry 1 : {{dataEntriesModel[0].dataEntryValue.username}}</p>
        <p *ngIf="child.key == 'de2'"> Data Entry 2 : {{dataEntriesModel[1].dataEntryValue.username}}</p>
        <p *ngIf="child.key == 'de3'"> Data Conflict : {{dataEntriesModel[2].dataEntryValue.username}}</p>
        <app-certimed-data-entry-viewer [dataEntryInfo]="child" [startDateTime]="startDateTime" [productionView]=true> </app-certimed-data-entry-viewer>
      </div>
      <mat-divider fxFlex="3" vertical inset *ngIf="child.key != 'de3'" id="verticalDivider"></mat-divider>
    </div>
  </div>
</cdk-virtual-scroll-viewport>
