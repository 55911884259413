import { Component, OnInit, Input, SimpleChanges, HostListener } from '@angular/core';
import { Dictionary } from '../Interfaces/dictionary';
import { certimedDCInfo, certimedModel } from '../Interfaces/certimedDCInfo';
import { DataEntryControllerService } from '../services/data-entry-controller.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { BlockModalComponent } from '../block-modal/block-modal.component';
import { CommonControllerService } from '../services/common-controller.service';

@Component({
  selector: 'app-certimed-data-conflict-viewer',
  templateUrl: './certimed-data-conflict-viewer.component.html',
  styleUrls: ['./certimed-data-conflict-viewer.component.css']
})
export class CertimedDataConflictViewerComponent implements OnInit {

  constructor(private dataEntryControllerService: DataEntryControllerService, private notification: MatSnackBar, public dialog: MatDialog, private commonControllerService: CommonControllerService) { }

  @Input('startDateTime') startDateTime: Date;
  nchild: Array<Dictionary> = new Array < Dictionary>();
  heightWindow: string; //We use that for stablishing the height in the scroll.
  folderHash: string;
  dataEntries: Array<certimedDCInfo> = new Array < certimedDCInfo>();

  dataConflict: certimedModel;
  isDC: boolean = true;
  isSaved: boolean = false;

  disableSend: boolean = false;
  disableBlock: boolean = false;
  disableClose: boolean = false;

  user: string;

  //SHORTCUTS
  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.isDC) {
      if (event.ctrlKey && event.code == "KeyS") { //CTRL + S
        //We need to save it
        event.preventDefault();
        if (!this.disableSend && !this.disableBlock) {
          this.save();
        }
        
      }
      else if (event.ctrlKey && event.code == "KeyB") { //CTRL + B
        //We need to block it
        event.preventDefault();
        event.stopImmediatePropagation();
        if (!this.disableBlock) {
          this.block();
        }
        //this.block();
      }
      else if (event.ctrlKey && event.code == "KeyQ") { //CTRL + Q
        //We need to close it
        event.preventDefault()
        event.stopImmediatePropagation();
        if (!this.disableClose) {
          this.close();
          
        }
      }
    }

  }

  ngOnInit() {

    this.folderHash = localStorage.getItem('folderHash');
    
    this.startInfo();
    var toolbarHeight = (document.getElementsByClassName("mat-toolbar-row").item(0) as HTMLElement).offsetHeight;
    this.heightWindow = document.documentElement.clientHeight - toolbarHeight + "px";
    console.log(this.heightWindow);
    document.getElementById("viewportDE").style.height = this.heightWindow;

    if (document.getElementById("verticalDivider"))
      document.getElementById("verticalDivider").style.height = document.getElementById("containerForm").style.height;


  }

  async startInfo() {
    var info = await this.commonControllerService.GetCommonInfo(this.folderHash);
    this.user = info.userName;
    if (info.typeDE == "Mensura One Day" || info.typeDE == "Unknown Document Type") {
      this.isDC = false;
    }
    else {
      this.isDC = true;

      var dataEntriesModel = await this.dataEntryControllerService.ObtainDEModel(this.folderHash, "CERTIMED", false) as certimedModel[];
      console.log(dataEntriesModel);
      this.dataConflict = Object.assign({}, dataEntriesModel[0]);

      var de1wrong = this.failsPart1(dataEntriesModel[0], dataEntriesModel[1]);
      var de2wrong = this.failsPart2(dataEntriesModel[0], dataEntriesModel[1]);
      var de3wrong = this.failsPart3(dataEntriesModel[0], dataEntriesModel[1]);
      var de4wrong = this.failsPart4(dataEntriesModel[0], dataEntriesModel[1]);
      var de5wrong = this.failsPart5(dataEntriesModel[0], dataEntriesModel[1]);
      var de6wrong = this.failsPart6(dataEntriesModel[0], dataEntriesModel[1]);
      var de7wrong = this.failsPart7(dataEntriesModel[0], dataEntriesModel[1]);


      this.dataEntries.push(
        {
          dataEntryValue: dataEntriesModel[0],
          de1wrong: de1wrong,
          de2wrong: de2wrong,
          de3wrong: de3wrong,
          de4wrong: de4wrong,
          de5wrong: de5wrong,
          de6wrong: de6wrong,
          de7wrong: de7wrong
        },
        {
          dataEntryValue: dataEntriesModel[1],
          de1wrong: de1wrong,
          de2wrong: de2wrong,
          de3wrong: de3wrong,
          de4wrong: de4wrong,
          de5wrong: de5wrong,
          de6wrong: de6wrong,
          de7wrong: de7wrong
        },
        {
          dataEntryValue: this.dataConflict,
          de1wrong: null,
          de2wrong: null,
          de3wrong: null,
          de4wrong: null,
          de5wrong: null,
          de6wrong: null,
          de7wrong: null
        }
      );

      console.log(this.dataEntries);

      this.nchild = new Array<Dictionary>(
        { key: "de1", value: JSON.stringify(this.dataEntries[0]) },
        { key: "de2", value: JSON.stringify(this.dataEntries[1]) },
        { key: "dc", value: JSON.stringify(this.dataEntries[2]) }
      )
    }

  }

  failsPart1(de1: certimedModel, de2: certimedModel) :string {
    var partFails = "";

    if (de1.patientFirstName == de2.patientFirstName) 
      partFails += 0;
    else {
      this.dataConflict.patientFirstName = null;
      partFails += 1;
    }

    if (de1.patientLastName == de2.patientLastName)
      partFails += 0;
    else {
      this.dataConflict.patientLastName = null;
      partFails += 1;
    }

    if (de1.nationalNumber == de2.nationalNumber)
      partFails += 0;
    else {
      this.dataConflict.nationalNumber = null;
      partFails += 1;
    }

    if (de1.personnelNumber == de2.personnelNumber)
      partFails += 0;
    else {
      this.dataConflict.personnelNumber = null;
      partFails += 1;
    }

    return partFails;
  }

  failsPart2(de1: certimedModel, de2: certimedModel): string {
    var partFails = "";

    if (de1.allowLeavingHome == de2.allowLeavingHome) 
      partFails += 0;
    else {
      this.dataConflict.allowLeavingHome = null;
      partFails += 1;
    }

    if (de1.incapacityReason == de2.incapacityReason)
      partFails += 0;
    else {
      this.dataConflict.incapacityReason = null;
      partFails += 1;
    }

    if (de1.hospitalizationStart == de2.hospitalizationStart)
      partFails += 0;
    else {
      this.dataConflict.hospitalizationStart = null;
      partFails += 1;
    }

    if (de1.hospitalizationEnd == de2.hospitalizationEnd)
      partFails += 0;
    else {
      this.dataConflict.hospitalizationEnd = null;
      partFails += 1;
    }

    if (de1.occupationalDisabilityStart == de2.occupationalDisabilityStart)
      partFails += 0;
    else {
      this.dataConflict.occupationalDisabilityStart = null;
      partFails += 1;
    }

    if (de1.occupationalDisabilityEnd == de2.occupationalDisabilityEnd)
      partFails += 0;
    else {
      this.dataConflict.occupationalDisabilityEnd = null;
      partFails += 1;
    }

    if (de1.certificateType == de2.certificateType) 
      partFails += 0;
    else {
      this.dataConflict.certificateType = null;
      partFails += 1;
    }

    if (de1.certificateDate == de2.certificateDate) 
      partFails += 0;
    else {
      this.dataConflict.certificateDate = null;
      partFails += 1;
    }

    if (de1.signature == de2.signature) 
      partFails += 0;
    else {
      this.dataConflict.signature = null;
      partFails += 1;
    }

    if (de1.stamp == de2.stamp)
      partFails += 0;
    else {
      this.dataConflict.stamp = null;
      partFails += 1;
    }

    if (de1.doctorName == de2.doctorName)
      partFails += 0;
    else {
      this.dataConflict.doctorName = null;
      partFails += 1;
    }

    if (de1.doctorPhoneNumber == de2.doctorPhoneNumber)
      partFails += 0;
    else {
      this.dataConflict.doctorPhoneNumber = null;
      partFails += 1;
    }

    if (de1.rizivNumber == de2.rizivNumber)
      partFails += 0;
    else {
      this.dataConflict.rizivNumber = null;
      partFails += 1;
    }

    if (de1.travelAbroadStart == de2.travelAbroadStart)
      partFails += 0;
    else {
      this.dataConflict.travelAbroadStart = null;
      partFails += 1;
    }

    if (de1.travelAbroadEnd == de2.travelAbroadEnd)
      partFails += 0;
    else {
      this.dataConflict.travelAbroadEnd = null;
      partFails += 1;
    }

    return partFails;

  }

  failsPart3(de1: certimedModel, de2: certimedModel): string {
    var partFails = "";

    if (de1.patientStreet == de2.patientStreet)
      partFails += 0;
    else {
      this.dataConflict.patientStreet = null;
      partFails += 1;
    }

    if (de1.patientHouseNumber == de2.patientHouseNumber)
      partFails += 0;
    else {
      this.dataConflict.patientHouseNumber = null;
      partFails += 1;
    }

    if (de1.patientBusNumber == de2.patientBusNumber)
      partFails += 0;
    else {
      this.dataConflict.patientBusNumber = null;
      partFails += 1;
    }

    if (de1.patientPostalNumber == de2.patientPostalNumber)
      partFails += 0;
    else {
      this.dataConflict.patientPostalNumber = null;
      partFails += 1;
    }

    if (de1.patientCity == de2.patientCity)
      partFails += 0;
    else {
      this.dataConflict.patientCity = null;
      partFails += 1;
    }

    if (de1.patientCountryCode == de2.patientCountryCode)
      partFails += 0;
    else {
      this.dataConflict.patientCountryCode = null;
      partFails += 1;
    }

    return partFails;

  }

  failsPart4(de1: certimedModel, de2: certimedModel): string {
    var partFails = "";

    if (de1.performanceDecreaseStart == de2.performanceDecreaseStart)
      partFails += 0;
    else {
      this.dataConflict.performanceDecreaseStart = null;
      partFails += 1;
    }

    if (de1.performanceDecreaseEnd == de2.performanceDecreaseEnd)
      partFails += 0;
    else {
      this.dataConflict.performanceDecreaseEnd = null;
      partFails += 1;
    }

    if (de1.performanceDecreasePercentage == de2.performanceDecreasePercentage)
      partFails += 0;
    else {
      this.dataConflict.performanceDecreasePercentage = null;
      partFails += 1;
    }

    return partFails;

  }

  failsPart5(de1: certimedModel, de2: certimedModel): string {
    var partFails = "";

    if (de1.companyName == de2.companyName)
      partFails += 0;
    else {
      this.dataConflict.companyName = null;
      partFails += 1;
    }

    if (de1.companyMensuraId == de2.companyMensuraId)
      partFails += 0;
    else {
      this.dataConflict.companyMensuraId = null;
      partFails += 1;
    }

    if (de1.resultDoctorId == de2.resultDoctorId)
      partFails += 0;
    else {
      this.dataConflict.resultDoctorId = null;
      partFails += 1;
    }

    if (de1.resultDoctorFirstName == de2.resultDoctorFirstName)
      partFails += 0;
    else {
      this.dataConflict.resultDoctorFirstName = null;
      partFails += 1;
    }

    if (de1.resultDoctorLastName == de2.resultDoctorLastName)
      partFails += 0;
    else {
      this.dataConflict.resultDoctorLastName = null;
      partFails += 1;
    }

    if (de1.resultCheck == de2.resultCheck)
      partFails += 0;
    else {
      this.dataConflict.resultCheck = null;
      partFails += 1;
    }

    if (de1.resultDate == de2.resultDate)
      partFails += 0;
    else {
      this.dataConflict.resultDate = null;
      partFails += 1;
    }

    if (de1.resultTime == de2.resultTime)
      partFails += 0;
    else {
      this.dataConflict.resultTime = null;
      partFails += 1;
    }

    return partFails;

  }

  failsPart6(de1: certimedModel, de2: certimedModel): string {
    var partFails = "";

    if (de1.temporaryResidenceStart == de2.temporaryResidenceStart)
      partFails += 0;
    else {
      this.dataConflict.temporaryResidenceStart = null;
      partFails += 1;
    }

    if (de1.temporaryResidenceEnd == de2.temporaryResidenceEnd)
      partFails += 0;
    else {
      this.dataConflict.temporaryResidenceEnd = null;
      partFails += 1;
    }

    if (de1.temporaryResidenceName == de2.temporaryResidenceName)
      partFails += 0;
    else {
      this.dataConflict.temporaryResidenceName = null;
      partFails += 1;
    }

    if (de1.temporaryResidenceStreet == de2.temporaryResidenceStreet)
      partFails += 0;
    else {
      this.dataConflict.temporaryResidenceStreet = null;
      partFails += 1;
    }

    if (de1.temporaryResidenceHouseNumber == de2.temporaryResidenceHouseNumber)
      partFails += 0;
    else {
      this.dataConflict.temporaryResidenceHouseNumber = null;
      partFails += 1;
    }

    if (de1.temporaryResidenceBusNumber == de2.temporaryResidenceBusNumber)
      partFails += 0;
    else {
      this.dataConflict.temporaryResidenceBusNumber = null;
      partFails += 1;
    }

    if (de1.temporaryResidencePostalNumber == de2.temporaryResidencePostalNumber)
      partFails += 0;
    else {
      this.dataConflict.temporaryResidencePostalNumber = null;
      partFails += 1;
    }

    if (de1.temporaryResidenceCity == de2.temporaryResidenceCity)
      partFails += 0;
    else {
      this.dataConflict.temporaryResidenceCity = null;
      partFails += 1;
    }

    if (de1.temporaryResidenceCountryCode == de2.temporaryResidenceCountryCode)
      partFails += 0;
    else {
      this.dataConflict.temporaryResidenceCountryCode = null;
      partFails += 1;
    }

    return partFails;

  }

  failsPart7(de1: certimedModel, de2: certimedModel): string {
    var partFails = "";

    if (de1.companyName == de2.companyName)
      partFails += 0;
    else {
      this.dataConflict.companyName = null;
      partFails += 1;
    }

    return partFails;
  }

  save() {
    this.disableSend = true;
    this.isSaved = !this.isSaved;
  }

  async close() {
    this.disableClose = true;
    this.disableBlock = true;
    var response = await this.commonControllerService.UnlockUser(this.folderHash);
    this.notification.open(response, "", {
      duration: 2000,
      panelClass: "centeredNotification"

    }).afterDismissed().toPromise().then((response) => { self.close(); this.disableClose = false; this.disableBlock = false });
  }

  checkDisable(event: boolean) {
    console.log(event);
    if (event == false) {
      this.disableSend = false;
    }
  }

  async block() {
    this.disableClose = true;
    this.disableBlock = true;

    const dialogRef = this.dialog.open(BlockModalComponent, {
      position: { top: '5px' },
      autoFocus: false,
      data: "Data entry"
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          console.log(result);

          if (result["reason"] != null && result["reason"] != undefined && result["remarks"] != null && result["remarks"] != undefined) {
            this.commonControllerService.SendBlock(this.folderHash, this.user, "CERTIMED", result, "DATA_ENTRY").then(response => {
              this.notification.open(response, "", {
                duration: 2000,
                panelClass: "centeredNotification"

              }).afterDismissed().toPromise().then(x => {
                if (response == "The DataEntry has been blocked successfully") {
                  this.close();
                }
                else {
                  this.disableClose = false;
                  this.disableBlock = false;
                }
              });
            });
          }
          else {
            this.disableClose = false;
            this.disableBlock = false;
          }
        }
      },
      error => {
        console.log('The dialog was closed ERROR ' + error);
      }
    );

    dialogRef.backdropClick().subscribe(result => {
      this.disableBlock = false;
      this.disableClose = false;
    })

    dialogRef.keydownEvents().subscribe(keyboardEvent => {
      console.log(keyboardEvent);
      if (keyboardEvent.code == "Escape") {
        this.disableBlock = false;
        this.disableClose = false;
      }
    })
  }

}
