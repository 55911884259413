import { Component, Input } from '@angular/core';
import { GHDataEntryViewerComponent } from '../../gh-data-entry-viewer/gh-data-entry-viewer.component';
import { GHDCInfo } from '../../interfaces/gh-dcinfo';

@Component({
  selector: 'app-gh-production-data-entry-viewer',
  templateUrl: '../../gh-data-entry-viewer/gh-data-entry-viewer.component.html',
  styleUrls: ['./gh-production-data-entry-viewer.component.css']
})
export class GHProductionDataEntryViewerComponent extends GHDataEntryViewerComponent {

  @Input() dataEntryInfo: GHDCInfo = null;

  ngOnInit() {
    if (this.dataEntryInfo) {
      super.ngOnInit();
      this.showSend = false;
      this.showClose = false;
    }
  }

  async getDefaultValues(): Promise<void> {
    this.documentTypeOptions.push(this.dataEntryInfo.documentType);
    this.insuredPersonOptions.push(this.dataEntryInfo.insuredPerson);

    this.defaultValues = this.dataEntryInfo;
    if (this.defaultValues.invoiceDate == null) {
      this.defaultValues.invoiceDate = ' ';
    }
    if (this.defaultValues.treatmentDateFrom == null) {
      this.defaultValues.treatmentDateFrom = ' ';
    }
    if (this.defaultValues.treatmentDateTo == null) {
      this.defaultValues.treatmentDateTo = ' ';
    }
    if (this.defaultValues.quantity == 'NaN') {
      this.defaultValues.quantity = 'NA';
    }

    /* TODO DELETE WHEN LIVE */
    this.fixCurrencyReceived();
    this.fixDatesReceived();
    /* END DELETE*/

    this.resetToDefaultValues(true);
    this.getInvoiceCurrency().setValue(this.defaultValues.invoiceCurrency); 
  }

  changeDataEntryVisibility(enabledDataEntry: boolean): void {
    this.dataEntryForm.disable();
    this.isFormEnabled = false;
  }

  applyDefaultValuesDependentOfDocumentType(): void {
    return;
  }
}
