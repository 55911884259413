import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormControl, FormGroup, AbstractControl, ValidatorFn } from '@angular/forms';
import { DataEntryControllerService } from '../services/data-entry-controller.service';
import { Dictionary } from '../Interfaces/dictionary';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RentaDataEntrySearchModalComponent } from '../renta-data-entry-search-modal/renta-data-entry-search-modal.component';
import { rentaDCInfo, rentaModel } from '../Interfaces/rentaDCInfo';
import { CommonControllerService } from '../services/common-controller.service';

@Component({
  selector: 'app-renta-data-entry-viewer',
  templateUrl: './renta-data-entry-viewer.component.html',
  styleUrls: ['./renta-data-entry-viewer.component.css']
})
export class RentaDataEntryViewerComponent implements OnInit {

  @Input() dataEntryInfo: Dictionary;
  @Input() startDateTime: Date;

  folderHash: string;
  user: string;
  rentaDCInfo: rentaDCInfo;
  rentaModel: rentaModel;
  idForm: string; //it is the name of the form needed for DC.
  isFilled: boolean = false;

  //LIST BOX
  requesterTypeOptions: Array<Dictionary>;
  documentTypeOptions: Array<Dictionary>;
  documentSubtypeOptions: Array<Dictionary>;
  reasonOptions: Array<string>;

  //VAR FOR SHOW THE DIFFERENTS FORM OF DATA_ENTRY
  showDE1: boolean = false;
  showStatus: boolean = false;
  showESResult: boolean = false;

  //OTHERS
  heightWindow: string; //We use that for stablishing the height in the scroll.
  datemask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  patternDate = /([1-2][0-9]|3[0-1]|0[1-9])\/(0[1-9]|1[0-2])\/(\d{4})/;
  patternAmount = "^[0-9]+(\,[0-9]{1,2})$";
  patternDayPayment = "^[0-9]+$";
  dateTimeMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/];
  patternDateTime = /([1-2][0-9]|3[0-1]|0[1-9])\/(0[1-9]|1[0-2])\/(\d{4}) ([01][0-9]|2[0-3]):([0-5][0-9])/;

  dataEntryForm = new FormGroup({

    //VAR OF CLIENT
    Client: new FormGroup({
      LicensePlate: new FormControl('', [Validators.required]),
      ClientID: new FormControl('', [Validators.required, Validators.maxLength(6), Validators.minLength(6), Validators.pattern("[0-9]{6}")]),
      ClientName: new FormControl()
    }),

    //VAR OF REQUESTER
    Requester: new FormGroup({
      RequesterType: new FormControl('', [Validators.required]),
      RequesterName: new FormControl('',[Validators.required]),
      RequesterAddress: new FormControl(),
      RequesterZipCode: new FormControl(),
      RequesterCity: new FormControl(),
      RequesterCountry: new FormControl(),
      RequesterEmail: new FormControl('', [Validators.email]),
      RequesterPhone: new FormControl(),
      RequesterFax: new FormControl()
    }),

    //VAR OF DOCUMENT
    Document: new FormGroup({
      DocumentType: new FormControl('', [Validators.required]),
      SubType: new FormControl('', [Validators.required]),
      DocumentReference: new FormControl(),
      DocumentDate: new FormControl('', [Validators.pattern(this.patternDate)]),
      InfractionDate: new FormControl('', [Validators.pattern(this.patternDateTime)]),
      DeadlineDate: new FormControl('', [Validators.pattern(this.patternDate)]),
      DaysPayment: new FormControl('', [Validators.pattern(this.patternDayPayment)]),
      Amount: new FormControl('', [Validators.pattern(this.patternAmount)]),
      StatusReason: new FormControl()
    })
  });

 

  constructor(private dataEntryControllerService: DataEntryControllerService, private notification: MatSnackBar, public dialog: MatDialog, private commonControllerService: CommonControllerService) { }

  ngOnInit() {
    this.requesterTypeOptions = new Array<Dictionary>(
      { key: "Police zone", value: "POLD"},
      { key: "Retribution company", value: "RETC" },
      { key: "Belgian city", value: "CITY" },
      { key: "Dutch fines", value: "CJIB" },
      { key: "Foreign fines", value: "FORE" },
      { key: "Other", value: "OTHR" }
    );
    this.documentTypeOptions = new Array<Dictionary>(
      { key: "Fine", value: "FINE" },
      { key: "Retribution", value: "RETR" },
      { key: "Warning", value: "WARN" },
      { key: "Request for information", value: "RFI" },
      { key: "Recall of cars", value: "RECALL" },
      { key: "Other", value: "OTHER" }
    );
    this.documentSubtypeOptions = new Array<Dictionary>(
      { key: "Initial fine document", value: "INIT" },
      { key: "First reminder", value: "REM1" },
      { key: "Second reminder", value: "REM2" },
      { key: "Third reminder", value: "REM3" }
    );
    this.reasonOptions = new Array<string>( "This is not a fine", "More than one license plate in the fine", "Bad Scan");

    this.showDEForm();
    //this.setRequiredDE3Validator();

    var url = new URL(window.location.href);
    this.folderHash = url.searchParams.get("folderHash");

    if (this.dataEntryInfo)
      this.idForm = "dataEntryForm_" + this.dataEntryInfo.key;
    else
      this.idForm = "dataEntryForm";

    this.startInfo();
  }

  async onSubmit(dataEntryForm) {
    console.log(dataEntryForm);
    if (this.dataEntryForm.invalid) {
      console.log("invalid");
    }
    else {
        var result = await this.dataEntryControllerService.SendForm(dataEntryForm, this.user, this.folderHash, "FINES", this.startDateTime, "");
        this.notification.open(result, "", {
            duration: 2000,
        });
    }
  }

  getErrorRequiredMessage(field: AbstractControl) {
    return field.hasError('required') ? 'You need to enter a value' : '';
  }

  showDEForm() {
    //Here we put the condition of the dataentry that we need to show.
    this.showDE1 = true;
    this.showStatus = false;
  }

  setRequiredDE3Validator() {

    const documentReference = this.dataEntryForm.get('Document').get('DocumentReference');
    const documentDate = this.dataEntryForm.get('Document').get('DocumentDate');
    const infractionDate = this.dataEntryForm.get('Document').get('InfractionDate');
    const amount = this.dataEntryForm.get('Document').get('Amount');

    this.dataEntryForm.get('Document').get('DocumentType').valueChanges.subscribe(documentType => {
      if (documentType === 'FINE' || documentType === 'RETR') {
        documentReference.setValidators([Validators.required]);
        documentDate.setValidators([Validators.required, Validators.pattern(this.patternDate)]);
        infractionDate.setValidators([Validators.required, Validators.pattern(this.patternDateTime)]);
        amount.setValidators([Validators.required, Validators.pattern(this.patternAmount)]);

        (<HTMLInputElement>document.getElementById("documentReference")).required = true;
        (<HTMLInputElement>document.getElementById("documentDate")).required = true;
        (<HTMLInputElement>document.getElementById("infractionDate")).required = true;
        (<HTMLInputElement>document.getElementById("amount")).required = true;

      }
      else {
        (<HTMLInputElement>document.getElementById("documentReference")).required = false;
        (<HTMLInputElement>document.getElementById("documentDate")).required = false;
        (<HTMLInputElement>document.getElementById("infractionDate")).required = false;
        (<HTMLInputElement>document.getElementById("amount")).required = false;

        documentReference.setValidators(null);
        documentDate.setValidators([Validators.pattern(this.patternDate)]);
        infractionDate.setValidators([Validators.pattern(this.patternDateTime)]);
        amount.setValidators([Validators.pattern(this.patternAmount)]);
      }

      if (documentType === 'OTHER') {
        this.showStatus = true;
        this.dataEntryForm.get('Document').get('StatusReason').enable();
      }
      else {
        this.showStatus = false;
        this.dataEntryForm.get('Document').get('StatusReason').disable();
      }

      documentReference.updateValueAndValidity();
      documentDate.updateValueAndValidity();
      infractionDate.updateValueAndValidity();
      amount.updateValueAndValidity();
    });

  }

  getErrorClientIDValidation() {
    var clientID = this.dataEntryForm.get('Client').get('ClientID');
    return clientID.hasError('pattern') ? 'Only 6 digits' :
      clientID.hasError('minlength') ? 'The length must be 6 digits' :
      clientID.hasError('maxlength') ? 'The length must be 6 digits' : '';
  }

  getErrorAmount() {
    var amount = this.dataEntryForm.get('Document').get('Amount');
    return amount.hasError('pattern') ? 'Number with 2 digits after comma' : '';
  }

  getErrorDaysPayment() {
    var daysPayment = this.dataEntryForm.get('Document').get('DaysPayment');
    return daysPayment.hasError('pattern') ? 'Only integer number' : '';
    }

  async startInfo() {
    var info = await this.commonControllerService.GetCommonInfo(this.folderHash);
    this.user = info.samName;

    if (this.dataEntryInfo && this.dataEntryInfo.key != "dc") {
      this.rentaDCInfo = JSON.parse(this.dataEntryInfo.value);
      this.rentaModel = this.rentaDCInfo.dataEntryValue;
      this.showESResult = true;
      this.showStatus = true;
      this.isFilled = true;
      this.dataEntryForm.markAllAsTouched();
      this.fillTheForm();
      this.updateValidations();
    }
    else {
      this.setRequiredDE3Validator();
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(RentaDataEntrySearchModalComponent, {
      data: this.dataEntryForm.get("Requester").get("RequesterType").value
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          console.log(result);
          this.showESResult = true;
          //TEMPORARY
          var requesterResult = this.dataEntryForm.get("Requester");
          requesterResult.setValue(result);
        }

      },
      error => {
        console.log('The dialog was closed ERROR ' + error);
      }
    );
  }

  fillTheForm() {

    const client = this.dataEntryForm.get('Client');
    const document = this.dataEntryForm.get('Document');
    const requester = this.dataEntryForm.get('Requester');

    client.setValue(this.rentaModel.Client);
    requester.setValue(this.rentaModel.Requester);

    //We change the document amount using a ','
    var amount = this.rentaModel.Document.Amount.split('.');
    this.rentaModel.Document.Amount = amount[0] + ',' + amount[1];

    //We put the document info.
    document.get('Amount').setValue(this.rentaModel.Document.Amount);
    document.get('DaysPayment').setValue(this.rentaModel.Document.DaysPayment);
    document.get('DeadlineDate').setValue(this.rentaModel.Document.DeadlineDate);
    document.get('DocumentDate').setValue(this.rentaModel.Document.DocumentDate);
    document.get('DocumentReference').setValue(this.rentaModel.Document.DocumentReference);
    document.get('DocumentType').setValue( this.documentTypeOptions.find(doc => doc.value == this.rentaModel.Document.DocumentType).key );
    document.get('InfractionDate').setValue(this.rentaModel.Document.InfractionDate);
    document.get('StatusReason').setValue(this.rentaModel.Document.StatusReason);
    document.get('SubType').setValue(this.documentSubtypeOptions.find(doc => doc.value == this.rentaModel.Document.SubType).key);

    //We put correct value of the select input.
    requester.get('RequesterType').setValue(this.requesterTypeOptions.find(doc => doc.value == this.rentaModel.Requester.RequesterType).key);

    document.updateValueAndValidity();
    client.updateValueAndValidity();
    requester.updateValueAndValidity();
  }

  areEqualValidator(value:string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (value == '1') {
        return { 'areEquals': true };
      }
      return null;
    };
  }

  updateValidations() {
    const client = this.dataEntryForm.get('Client');
    const requester = this.dataEntryForm.get('Requester');
    const document = this.dataEntryForm.get('Document');

    //Update client validations
    client.get("LicensePlate").setValidators([client.get("LicensePlate").validator, this.areEqualValidator(this.rentaDCInfo.clientWrong[0])]);
    client.get("ClientID").setValidators([client.get("ClientID").validator, this.areEqualValidator(this.rentaDCInfo.clientWrong[1])]);
    client.get("ClientName").setValidators(this.areEqualValidator(this.rentaDCInfo.clientWrong[2]));

    client.updateValueAndValidity();

    //Update requester validations
    requester.get("RequesterType").setValidators([requester.get("RequesterType").validator, this.areEqualValidator(this.rentaDCInfo.requesterWrong[0])]);
    requester.get("RequesterName").setValidators([requester.get("RequesterName").validator, this.areEqualValidator(this.rentaDCInfo.requesterWrong[1])]);
    requester.get("RequesterAddress").setValidators(this.areEqualValidator(this.rentaDCInfo.requesterWrong[2]));

    requester.get("RequesterZipCode").setValidators(this.areEqualValidator(this.rentaDCInfo.requesterWrong[3]));
    requester.get("RequesterCity").setValidators(this.areEqualValidator(this.rentaDCInfo.requesterWrong[4]));
    requester.get("RequesterCountry").setValidators(this.areEqualValidator(this.rentaDCInfo.requesterWrong[5]));

    requester.get("RequesterEmail").setValidators([requester.get("RequesterEmail").validator, this.areEqualValidator(this.rentaDCInfo.requesterWrong[6])]);
    requester.get("RequesterPhone").setValidators(this.areEqualValidator(this.rentaDCInfo.requesterWrong[7]));
    requester.get("RequesterFax").setValidators(this.areEqualValidator(this.rentaDCInfo.requesterWrong[8]));

    requester.updateValueAndValidity();

    //Update document validations
    document.get("DocumentType").setValidators([document.get("DocumentType").validator, this.areEqualValidator(this.rentaDCInfo.documentWrong[0])]);
    document.get("SubType").setValidators([document.get("SubType").validator, this.areEqualValidator(this.rentaDCInfo.documentWrong[1])]);
    document.get("DocumentReference").setValidators(this.areEqualValidator(this.rentaDCInfo.documentWrong[2]));

    document.get("DocumentDate").setValidators([document.get("DocumentDate").validator, this.areEqualValidator(this.rentaDCInfo.documentWrong[3])]);
    document.get("InfractionDate").setValidators([document.get("InfractionDate").validator, this.areEqualValidator(this.rentaDCInfo.documentWrong[4])]);
    document.get("DeadlineDate").setValidators([document.get("DeadlineDate").validator, this.areEqualValidator(this.rentaDCInfo.documentWrong[5])]);

    document.get("DaysPayment").setValidators([document.get("DaysPayment").validator, this.areEqualValidator(this.rentaDCInfo.documentWrong[6])]);
    document.get("Amount").setValidators([document.get("Amount").validator, this.areEqualValidator(this.rentaDCInfo.documentWrong[7])]);
    document.get("StatusReason").setValidators(this.areEqualValidator(this.rentaDCInfo.documentWrong[8]));

    document.updateValueAndValidity();
  }
}
