
<form id="{{idForm}}" [formGroup]="dataEntryForm" (ngSubmit)="onSubmit(dataEntryForm.value)">
  
  <div id="de1" *ngIf="showDE1" layout="column" formGroupName="Client">
    <h3> Client </h3>

    <mat-form-field appearance="legacy">
      <mat-label>License Plate</mat-label>
      <input matInput formControlName="LicensePlate" required type="text" [readonly]="isFilled">
      <mat-error *ngIf="!isFilled && dataEntryForm.get('Client').get('LicensePlate').invalid">{{getErrorRequiredMessage(dataEntryForm.get('Client').get('LicensePlate'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="legacy">
      <mat-label>Client ID</mat-label>
      <input type="text" pattern="[0-9]{6}" minlength="6" maxlength="6" matInput formControlName="ClientID" required [readonly]="isFilled">
      <mat-error *ngIf="!isFilled && dataEntryForm.get('Client').get('ClientID').invalid">{{getErrorRequiredMessage(dataEntryForm.get('Client').get('ClientID'))}}</mat-error>
      <mat-error *ngIf="!isFilled && dataEntryForm.get('Client').get('ClientID').invalid">{{getErrorClientIDValidation()}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="legacy">
      <mat-label>Client Name</mat-label>
      <input type="text" matInput formControlName="ClientName" [readonly]="isFilled">
      <!--<mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>-->
    </mat-form-field>

  </div>

  <mat-divider></mat-divider>

  <div id="de2" formGroupName="Requester">
    <h3> Requester </h3>

    <mat-form-field appearance="legacy" *ngIf="!isFilled">
      <mat-label>Requester Type</mat-label>
      <mat-select required disableOptionCentering formControlName="RequesterType">
        <mat-option> </mat-option>
        <mat-option *ngFor="let option of requesterTypeOptions" value="{{option.value}}"> {{option.key}}</mat-option>
      </mat-select>
      <mat-error *ngIf="dataEntryForm.get('Requester').get('RequesterType').invalid">{{getErrorRequiredMessage(dataEntryForm.get('Requester').get('RequesterType'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="legacy" *ngIf="isFilled">
      <mat-label>Requester Type</mat-label>
      <input matInput formControlName="RequesterType" required type="text" [readonly]="isFilled">
    </mat-form-field>

    <button type="button" mat-button id="search" (click)="openDialog()" *ngIf="!isFilled"> Search </button>

    <mat-form-field appearance="legacy" *ngIf="showESResult">
      <mat-label>Requester Name</mat-label>
      <input type="text" matInput required formControlName="RequesterName" readonly>
      <mat-error *ngIf="!isFilled && dataEntryForm.get('Requester').get('RequesterName').invalid">{{getErrorRequiredMessage(dataEntryForm.get('Requester').get('RequesterName'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="legacy" *ngIf="showESResult">
      <mat-label>Requester Address</mat-label>
      <input type="text" matInput required formControlName="RequesterAddress" readonly>
      <mat-error *ngIf="!isFilled && dataEntryForm.get('Requester').get('RequesterAddress').invalid">{{getErrorRequiredMessage(dataEntryForm.get('Requester').get('RequesterAddress'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="legacy" *ngIf="showESResult">
      <mat-label>Requester Zip Code</mat-label>
      <input type="text" matInput required formControlName="RequesterZipCode" readonly>
      <mat-error *ngIf="!isFilled && dataEntryForm.get('Requester').get('RequesterZipCode').invalid">{{getErrorRequiredMessage(dataEntryForm.get('Requester').get('RequesterZipCode'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="legacy" *ngIf="showESResult">
      <mat-label>Requester City</mat-label>
      <input type="text" matInput required formControlName="RequesterCity" readonly>
      <mat-error *ngIf="!isFilled && dataEntryForm.get('Requester').get('RequesterCity').invalid">{{getErrorRequiredMessage(dataEntryForm.get('Requester').get('RequesterCity'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="legacy" *ngIf="showESResult">
      <mat-label>Requester Country</mat-label>
      <input type="text" matInput required formControlName="RequesterCountry" readonly>
      <mat-error *ngIf="!isFilled && dataEntryForm.get('Requester').get('RequesterCountry').invalid">{{getErrorRequiredMessage(dataEntryForm.get('Requester').get('RequesterCountry'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="legacy" *ngIf="showESResult">
      <mat-label>Requester E-mail</mat-label>
      <input type="email" matInput formControlName="RequesterEmail" readonly>
    </mat-form-field>

    <mat-form-field appearance="legacy" *ngIf="showESResult">
      <mat-label>Requester Phone</mat-label>
      <input type="tel" matInput formControlName="RequesterPhone" readonly>
      <!--<mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>-->
    </mat-form-field>

    <mat-form-field appearance="legacy" *ngIf="showESResult">
      <mat-label>Requester Fax</mat-label>
      <input type="text" matInput formControlName="RequesterFax" readonly>
      <!--<mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>-->
    </mat-form-field>

  </div>

  <mat-divider></mat-divider>

  <div id="de3" formGroupName="Document">
    <h3> Document </h3>

    <mat-form-field appearance="legacy" *ngIf="!isFilled">
      <mat-label>Document Type</mat-label>
      <mat-select required disableOptionCentering formControlName="DocumentType">
        <mat-option> </mat-option>
        <mat-option *ngFor="let option of documentTypeOptions" value="{{option.value}}"> {{option.key}}</mat-option>
      </mat-select>
      <mat-error *ngIf="dataEntryForm.get('Document').get('DocumentType').invalid">{{getErrorRequiredMessage(dataEntryForm.get('Document').get('DocumentType'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="legacy" *ngIf="isFilled">
      <mat-label>Document Type</mat-label>
      <input type="text" matInput formControlName="DocumentType" [readonly]="isFilled" required>
    </mat-form-field>

    <mat-form-field appearance="legacy" *ngIf="!isFilled">
      <mat-label>Document Subtype</mat-label>
      <mat-select required disableOptionCentering formControlName="SubType">
        <mat-option> </mat-option>
        <mat-option *ngFor="let option of documentSubtypeOptions" value="{{option.value}}"> {{option.key}}</mat-option>
      </mat-select>
      <mat-error *ngIf="dataEntryForm.get('Document').get('SubType').invalid">{{getErrorRequiredMessage(dataEntryForm.get('Document').get('SubType'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="legacy" *ngIf="isFilled">
      <mat-label>Document Subtype</mat-label>
      <input type="text" matInput formControlName="SubType" [readonly]="isFilled">
    </mat-form-field>

    <mat-form-field appearance="legacy">
      <mat-label>Document Reference</mat-label>
      <input type="text" matInput formControlName="DocumentReference" id="documentReference" [readonly]="isFilled">
      <mat-error *ngIf="!isFilled && dataEntryForm.get('Document').get('DocumentReference').invalid">{{getErrorRequiredMessage(dataEntryForm.get('Document').get('DocumentReference'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="legacy">
      <mat-label>Document Date</mat-label>
      <input [textMask]="{mask: datemask}" pattern="^([1-2][0-9]|(3)[0-1]|(0)[1-9])(\/)((0)[1-9]|(1)[0-2])(\/)(\d{4})$" placeholder="dd/mm/yyyy" matInput formControlName="DocumentDate" id="documentDate" [readonly]="isFilled">
      <mat-error *ngIf="!isFilled && dataEntryForm.get('Document').get('DocumentDate').invalid">{{getErrorRequiredMessage(dataEntryForm.get('Document').get('DocumentDate'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="legacy">
      <mat-label>Infraction Date</mat-label>
      <input [textMask]="{mask: dateTimeMask}" pattern="^([1-2][0-9]|3[0-1]|0[1-9])\/(0[1-9]|1[0-2])\/(\d{4}) ([01][0-9]|2[0-3]):([0-5][0-9])$" matInput formControlName="InfractionDate" placeholder="dd/mm/yyyy hh:mm" id="infractionDate" [readonly]="isFilled">
      <mat-error *ngIf="!isFilled && dataEntryForm.get('Document').get('InfractionDate').invalid">{{getErrorRequiredMessage(dataEntryForm.get('Document').get('InfractionDate'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="legacy">
      <mat-label>Deadline Date</mat-label>
      <input matInput [textMask]="{mask: datemask}" pattern="^([1-2][0-9]|(3)[0-1]|(0)[1-9])(\/)((0)[1-9]|(1)[0-2])(\/)(\d{4})$" formControlName="DeadlineDate" placeholder="dd/mm/yyyy" id="deadlineDate" [readonly]="isFilled">
    </mat-form-field>

    <mat-form-field appearance="legacy">
      <mat-label>Payment within X days</mat-label>
      <input type="text" matInput formControlName="DaysPayment" pattern="{{patternDayPayment}}" [readonly]="isFilled">
      <mat-error *ngIf="!isFilled && dataEntryForm.get('Document').get('DaysPayment').invalid">{{getErrorDaysPayment()}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="legacy">
      <mat-label>Amount </mat-label>
      <input matInput formControlName="Amount" id="amount" pattern="{{patternAmount}}" placeholder="x,yy" [readonly]="isFilled">
      <mat-error *ngIf="!isFilled && dataEntryForm.get('Document').get('Amount').invalid">{{getErrorRequiredMessage(dataEntryForm.get('Document').get('Amount'))}}</mat-error>
      <mat-error *ngIf="!isFilled && dataEntryForm.get('Document').get('Amount').invalid">{{getErrorAmount()}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="legacy" *ngIf="showStatus && !isFilled">
      <mat-label>Reason </mat-label>
      <mat-select required disableOptionCentering formControlName="StatusReason">
        <mat-option> </mat-option>
        <mat-option *ngFor="let option of reasonOptions" value="{{option}}"> {{ option }}</mat-option>
      </mat-select>
      <mat-error *ngIf="dataEntryForm.get('Document').get('StatusReason').invalid">{{getErrorRequiredMessage(dataEntryForm.get('Document').get('StatusReason'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="legacy" *ngIf="showStatus && isFilled">
      <mat-label>Reason</mat-label>
      <input type="text" matInput formControlName="StatusReason" pattern="{{patternDayPayment}}" [readonly]="isFilled">
    </mat-form-field>

    <!-- END -->

  </div>

  <mat-divider></mat-divider>

  <div id="buttons" *ngIf="!dataEntryInfo || dataEntryInfo.key == 'dc'">
    <button mat-raised-button id="save" type="submit"> Save </button>
    <button type="button" mat-button id="close"> Close </button>
    <button type="button" mat-button id="block"> Block </button>
  </div>

</form>

