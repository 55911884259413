import { Component, OnInit, Input } from '@angular/core';
import { Dictionary } from '../Interfaces/dictionary';
import { certimedDCInfo, certimedModel } from '../Interfaces/certimedDCInfo';
import { DataEntryControllerService } from '../services/data-entry-controller.service';

@Component({
  selector: 'app-certimed-production-view',
  templateUrl: './certimed-production-view.component.html',
  styleUrls: ['./certimed-production-view.component.css']
})
export class CertimedProductionViewComponent implements OnInit {

  constructor(private dataEntryControllerService: DataEntryControllerService) { }

  @Input('startDateTime') startDateTime: Date;
  nchild: Array<Dictionary> = new Array<Dictionary>();
  heightWindow: string; //We use that for stablishing the height in the scroll.
  folderHash: string;
  dataEntriesModel: Array<certimedDCInfo> = new Array<certimedDCInfo>();
  existChild: boolean = true;

  ngOnInit() {

    this.folderHash = localStorage.getItem('folderHash');
    this.startInfo();
    var toolbarHeight = (document.getElementsByClassName("mat-toolbar-row").item(0) as HTMLElement).offsetHeight;
    this.heightWindow = document.documentElement.clientHeight - toolbarHeight + "px";
    console.log(this.heightWindow);
    document.getElementById("viewportDE").style.height = this.heightWindow;

    if (document.getElementById("verticalDivider"))
      document.getElementById("verticalDivider").style.height = document.getElementById("containerForm").style.height;
  }

  async startInfo() {
    var dataEntries = await this.dataEntryControllerService.ObtainDEModel(this.folderHash, "CERTIMED", true) as certimedModel[];

    if (dataEntries.length > 0) {
      if (dataEntries.length == 1) {
        this.dataEntriesModel.push({
          dataEntryValue: dataEntries[0],
          de1wrong: null,
          de2wrong: null,
          de3wrong: null,
          de4wrong: null,
          de5wrong: null,
          de6wrong: null,
          de7wrong: null,
        })
        this.nchild.push({ key: "de1", value: JSON.stringify(this.dataEntriesModel[0]) });
      }
      else {
        var de1wrong = this.failsPart1(dataEntries[0], dataEntries[1]);
        var de2wrong = this.failsPart2(dataEntries[0], dataEntries[1]);
        var de3wrong = this.failsPart3(dataEntries[0], dataEntries[1]);
        var de4wrong = this.failsPart4(dataEntries[0], dataEntries[1]);
        var de5wrong = this.failsPart5(dataEntries[0], dataEntries[1]);
        var de6wrong = this.failsPart6(dataEntries[0], dataEntries[1]);
        var de7wrong = this.failsPart7(dataEntries[0], dataEntries[1]);

        this.dataEntriesModel.push(
          {
            dataEntryValue: dataEntries[0],
            de1wrong: de1wrong,
            de2wrong: de2wrong,
            de3wrong: de3wrong,
            de4wrong: de4wrong,
            de5wrong: de5wrong,
            de6wrong: de6wrong,
            de7wrong: de7wrong
          },
          {
            dataEntryValue: dataEntries[1],
            de1wrong: de1wrong,
            de2wrong: de2wrong,
            de3wrong: de3wrong,
            de4wrong: de4wrong,
            de5wrong: de5wrong,
            de6wrong: de6wrong,
            de7wrong: de7wrong
          }
        );

        if (dataEntries.length == 2) {
          this.nchild.push({ key: "de1", value: JSON.stringify(this.dataEntriesModel[0]) });
          this.nchild.push({ key: "de2", value: JSON.stringify(this.dataEntriesModel[1]) });
        }
        else {
          this.dataEntriesModel.push({
            dataEntryValue: dataEntries[2],
            de1wrong: null,
            de2wrong: null,
            de3wrong: null,
            de4wrong: null,
            de5wrong: null,
            de6wrong: null,
            de7wrong: null
          })

          this.nchild = new Array<Dictionary>(
            { key: "de1", value: JSON.stringify(this.dataEntriesModel[0]) },
            { key: "de2", value: JSON.stringify(this.dataEntriesModel[1]) },
            { key: "de3", value: JSON.stringify(this.dataEntriesModel[2]) }
          )
        }
      }
    }
    else
      this.existChild = false;

    console.log(this.nchild);

  }



  failsPart1(de1: certimedModel, de2: certimedModel): string {
    var partFails = "";

    if (de1.patientFirstName == de2.patientFirstName)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.patientLastName == de2.patientLastName)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.nationalNumber == de2.nationalNumber)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.personnelNumber == de2.personnelNumber)
      partFails += 0;
    else {
      partFails += 1;
    }

    return partFails;
  }

  failsPart2(de1: certimedModel, de2: certimedModel): string {
    var partFails = "";

    if (de1.allowLeavingHome == de2.allowLeavingHome)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.incapacityReason == de2.incapacityReason)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.hospitalizationStart == de2.hospitalizationStart)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.hospitalizationEnd == de2.hospitalizationEnd)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.occupationalDisabilityStart == de2.occupationalDisabilityStart)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.occupationalDisabilityEnd == de2.occupationalDisabilityEnd)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.certificateType == de2.certificateType)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.certificateDate == de2.certificateDate)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.signature == de2.signature)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.stamp == de2.stamp)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.doctorName == de2.doctorName)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.doctorPhoneNumber == de2.doctorPhoneNumber)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.rizivNumber == de2.rizivNumber)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.travelAbroadStart == de2.travelAbroadStart)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.travelAbroadEnd == de2.travelAbroadEnd)
      partFails += 0;
    else {
      partFails += 1;
    }

    return partFails;

  }

  failsPart3(de1: certimedModel, de2: certimedModel): string {
    var partFails = "";

    if (de1.patientStreet == de2.patientStreet)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.patientHouseNumber == de2.patientHouseNumber)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.patientBusNumber == de2.patientBusNumber)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.patientPostalNumber == de2.patientPostalNumber)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.patientCity == de2.patientCity)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.patientCountryCode == de2.patientCountryCode)
      partFails += 0;
    else {
      partFails += 1;
    }

    return partFails;

  }

  failsPart4(de1: certimedModel, de2: certimedModel): string {
    var partFails = "";

    if (de1.performanceDecreaseStart == de2.performanceDecreaseStart)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.performanceDecreaseEnd == de2.performanceDecreaseEnd)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.performanceDecreasePercentage == de2.performanceDecreasePercentage)
      partFails += 0;
    else {
      partFails += 1;
    }

    return partFails;

  }

  failsPart5(de1: certimedModel, de2: certimedModel): string {
    var partFails = "";

    if (de1.companyName == de2.companyName)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.companyMensuraId == de2.companyMensuraId)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.resultDoctorId == de2.resultDoctorId)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.resultDoctorFirstName == de2.resultDoctorFirstName)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.resultDoctorLastName == de2.resultDoctorLastName)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.resultCheck == de2.resultCheck)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.resultDate == de2.resultDate)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.resultTime == de2.resultTime)
      partFails += 0;
    else {
      partFails += 1;
    }

    return partFails;

  }

  failsPart6(de1: certimedModel, de2: certimedModel): string {
    var partFails = "";

    if (de1.temporaryResidenceStart == de2.temporaryResidenceStart)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.temporaryResidenceEnd == de2.temporaryResidenceEnd)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.temporaryResidenceName == de2.temporaryResidenceName)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.temporaryResidenceStreet == de2.temporaryResidenceStreet)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.temporaryResidenceHouseNumber == de2.temporaryResidenceHouseNumber)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.temporaryResidenceBusNumber == de2.temporaryResidenceBusNumber)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.temporaryResidencePostalNumber == de2.temporaryResidencePostalNumber)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.temporaryResidenceCity == de2.temporaryResidenceCity)
      partFails += 0;
    else {
      partFails += 1;
    }

    if (de1.temporaryResidenceCountryCode == de2.temporaryResidenceCountryCode)
      partFails += 0;
    else {
      partFails += 1;
    }

    return partFails;

  }

  failsPart7(de1: certimedModel, de2: certimedModel): string {
    var partFails = "";

    if (de1.companyName == de2.companyName)
      partFails += 0;
    else {
      partFails += 1;
    }

    return partFails;
  }

}
