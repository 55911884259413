<div id="modal">
  <h1 mat-dialog-title> Searching Doctor Information </h1>
  <div mat-dialog-content id="content">

    <form id="form" [formGroup]="search" (ngSubmit)="onSubmit(search.value)" autocomplete="off">
      <mat-form-field appearance="legacy">
        <mat-label>{{'doctorName' | translate}}</mat-label>
        <input type="text" matInput formControlName="doctorName" >
      </mat-form-field>

      <mat-form-field appearance="legacy">
        <mat-label>{{'doctorPhoneNumber' | translate}}</mat-label>
        <input type="text" matInput formControlName="doctorPhoneNumber" >
      </mat-form-field>

      <mat-form-field appearance="legacy">
        <mat-label>{{'rizivNumber' | translate}}</mat-label>
        <input type="text" matInput formControlName="rizivNumber" >
      </mat-form-field>

      <button mat-raised-button id="search" type="submit"> Search </button>

    </form>
    <mat-divider></mat-divider>
    <div id="table">
      <article id="title">
        <h3> Results </h3>
        <p> Listed: {{totalRows}} - Total: {{totalResult}} </p>
      </article>

      <div id="tableResult">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="showResult">

          <!-- Name Column -->
          <ng-container matColumnDef="doctorName">
            <th mat-header-cell *matHeaderCellDef> Naam Arts </th>
            <td mat-cell (dblclick)="doubleClick(element)" *matCellDef="let element"> {{element.doctorName}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="doctorPhoneNumber">
            <th mat-header-cell *matHeaderCellDef> Telefoonnummer Arts </th>
            <td mat-cell (dblclick)="doubleClick(element)" *matCellDef="let element"> {{element.doctorPhoneNumber}} </td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="rizivNumber">
            <th mat-header-cell *matHeaderCellDef> Rizivnummer </th>
            <td mat-cell (dblclick)="doubleClick(element)" *matCellDef="let element"> {{element.rizivNumber}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </div>

</div>
