<div id="modal">
  <h1 mat-dialog-title> Searching request information </h1>
  <div mat-dialog-content>

    <form id="form" [formGroup]="Requester" (ngSubmit)="onSubmit(Requester.value)">
      <mat-form-field appearance="legacy">
        <mat-label>Requester Name</mat-label>
        <input type="text" matInput formControlName="RequesterName">
      </mat-form-field>

      <mat-form-field appearance="legacy">
        <mat-label>Requester Address</mat-label>
        <input type="text" matInput formControlName="RequesterAddress">
      </mat-form-field>

      <mat-form-field appearance="legacy">
        <mat-label>Requester Zip Code</mat-label>
        <input type="text" matInput formControlName="RequesterZipCode">
      </mat-form-field>

      <mat-form-field appearance="legacy">
        <mat-label>Requester City</mat-label>
        <input type="text" matInput formControlName="RequesterCity">
      </mat-form-field>

      <mat-form-field appearance="legacy">
        <mat-label>Requester Country</mat-label>
        <input type="text" matInput formControlName="RequesterCountry" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredOptions | async" value="{{option.key}}" id="option">
            {{option.key}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="legacy">
        <mat-label>Requester E-mail</mat-label>
        <input type="email" matInput formControlName="RequesterEmail">
      </mat-form-field>

      <mat-form-field appearance="legacy">
        <mat-label>Requester Phone</mat-label>
        <input type="tel" matInput formControlName="RequesterPhone">
      </mat-form-field>

      <mat-form-field appearance="legacy">
        <mat-label>Requester Fax</mat-label>
        <input type="text" matInput formControlName="RequesterFax">
      </mat-form-field>

      <button mat-raised-button id="search" type="submit"> Search </button>

    </form>
    <mat-divider></mat-divider>
    <div id="table">
      <h3> Results </h3>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef> No. </th>
          <td mat-cell (dblclick)="doubleClick(element)" *matCellDef="let element"> {{element.position}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell (dblclick)="doubleClick(element)" *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="weight">
          <th mat-header-cell *matHeaderCellDef> Weight </th>
          <td mat-cell (dblclick)="doubleClick(element)" *matCellDef="let element"> {{element.weight}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="symbol">
          <th mat-header-cell *matHeaderCellDef> Symbol </th>
          <td mat-cell (dblclick)="doubleClick(element)" *matCellDef="let element"> {{element.symbol}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
  <!--<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">No Thanks</button>
    <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button>
  </div>-->
</div>
