<div *ngIf="isDC">
  <div fxFlex="33" id="containerForm" *ngFor="let child of nchild">
    <div fxFlex="97">
      <p *ngIf="child.key == 'de1'"> Data Entry 1 : {{dataEntries[0].dataEntryValue.username}}</p>
      <p *ngIf="child.key == 'de2'"> Data Entry 2 : {{dataEntries[1].dataEntryValue.username}}</p>
      <p *ngIf="child.key == 'dc'"> Data Conflict </p>
      <app-certimed-data-entry-viewer [dataEntryInfo]="child" [startDateTime]="startDateTime" *ngIf="child.key != 'dc'"> </app-certimed-data-entry-viewer>
      <app-certimed-data-entry-viewer [dataEntryInfo]="child" [startDateTime]="startDateTime" (disableSave)="checkDisable($event)" [isSaved]="isSaved" *ngIf="child.key == 'dc'"> </app-certimed-data-entry-viewer>
    </div>
    <mat-divider fxFlex="3" vertical inset *ngIf="child.key != 'dc'" id="verticalDivider"></mat-divider>
  </div>
</div>
<div *ngIf="!isDC" class="DCHeight">
  <app-certimed-data-entry-viewer [startDateTime]="startDateTime"> </app-certimed-data-entry-viewer>
</div>
<div id="buttons" *ngIf="isDC">
<button mat-raised-button id="save" (click)="save()" [disabled]="disableSend || disableBlock" > Save </button>
<button mat-raised-button type="button" id="close" (click)="close()" [disabled]="disableClose"> Close </button>
<button mat-raised-button type="button" id="block" (click)="block()" [disabled]="disableBlock"> Block </button>
</div>
