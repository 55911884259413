<div id="modal">
  <h1 mat-dialog-title> Searching Patient Information </h1>
  <div mat-dialog-content id="content">

    <form id="form" [formGroup]="search" (ngSubmit)="onSubmit(search.value)" autocomplete="off">
      <mat-form-field appearance="legacy">
        <mat-label>Voornaam</mat-label>
        <input type="text" matInput formControlName="voornaam">
      </mat-form-field>

      <mat-form-field appearance="legacy">
        <mat-label>Naam</mat-label>
        <input type="text" matInput formControlName="naam">
      </mat-form-field>

      <mat-form-field appearance="legacy">
        <mat-label>General search</mat-label>
        <input type="text" matInput formControlName="general">
      </mat-form-field>

      <button mat-raised-button id="search" type="submit"> Search </button>

    </form>
    <mat-divider></mat-divider>
    <div id="table">
      <article id="title">
        <h3> Results </h3>
        <p> Listed: {{totalRows}} - Total: {{totalResult}} </p>
      </article>

      <div id="tableResult">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="showResult">

          <ng-container matColumnDef="voornaam">
            <th mat-header-cell class="borderCell" *matHeaderCellDef> Voornaam </th>
            <td mat-cell class="borderCell" (dblclick)="doubleClick(element)" *matCellDef="let element"> {{element.voornaam}} </td>
          </ng-container>

          <ng-container matColumnDef="naam">
            <th mat-header-cell class="borderCell" *matHeaderCellDef> Naam </th>
            <td mat-cell (dblclick)="doubleClick(element)" *matCellDef="let element"> {{element.naam}} </td>
          </ng-container>

          <ng-container matColumnDef="personeelNo">
            <th mat-header-cell class="borderCell" *matHeaderCellDef> PersoneelNo </th>
            <td mat-cell (dblclick)="doubleClick(element)" *matCellDef="let element"> {{element.personeelNo}} </td>
          </ng-container>

          <ng-container matColumnDef="filiaalNummer">
            <th mat-header-cell *matHeaderCellDef> FiliaalNummer </th>
            <td mat-cell  (dblclick)="doubleClick(element)" *matCellDef="let element"> {{element.filiaalNummer}} </td>
          </ng-container>

          <ng-container matColumnDef="rijks">
            <th mat-header-cell *matHeaderCellDef> Rijks </th>
            <td mat-cell (dblclick)="doubleClick(element)" *matCellDef="let element"> {{element.rijks}} </td>
          </ng-container>
          
          <ng-container matColumnDef="mensuraId">
            <th mat-header-cell *matHeaderCellDef> MensuraID </th>
            <td mat-cell (dblclick)="doubleClick(element)" *matCellDef="let element"> {{element.mensuraId}} </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </div>

</div>
