  <form #deform id="{{idForm}}" [formGroup]="dataEntryForm" (ngSubmit)="onSubmit(dataEntryForm.value)" autocomplete="off">

  <div id="de1" *ngIf="showDE1" layout="column" formGroupName="de1">
    <h3> Data Entry Process 1</h3>
    <!--<div flex layout>-->

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de1').get('patientFirstName')) }">
      <mat-label>{{'patientFirstName' | translate}}</mat-label>
      <input matInput formControlName="patientFirstName" required [readonly]="isFilled">
      <mat-error *ngIf="!isFilled && dataEntryForm.get('de1').get('patientFirstName').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de1').get('patientFirstName'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de1').get('patientLastName')) }">
      <mat-label>{{'patientLastName' | translate}}</mat-label>
      <input type="text" matInput formControlName="patientLastName" required [readonly]="isFilled">
      <mat-error *ngIf="!isFilled && dataEntryForm.get('de1').get('patientLastName').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de1').get('patientLastName'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de1').get('nationalNumber')) }">
      <mat-label>{{'nationalNumber' | translate}}</mat-label>
      <input type="text" matInput formControlName="nationalNumber" [readonly]="isFilled">
      <!--<mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>-->
    </mat-form-field>


    <mat-form-field appearance="outline" flex [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de1').get('personnelNumber')) }">
      <mat-label>{{'personnelNumber' | translate}}</mat-label>
      <input type="text" matInput formControlName="personnelNumber" [readonly]="isFilled">
      <!--<mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>-->
    </mat-form-field>
    <!--</div>-->

  </div>

  <mat-divider *ngIf="showDE1"></mat-divider>

  <div id="de2" *ngIf="showDE2" formGroupName="de2">
    <h3> Data Entry Process 2</h3>

    <mat-form-field appearance="outline" *ngIf="!isFilled" [class.DCview]="isDCView">
      <mat-label>{{'allowLeavingHome' | translate}}</mat-label>
      <mat-select required disableOptionCentering formControlName="allowLeavingHome">
        <mat-option> </mat-option>
        <mat-option *ngFor="let option of verlatenVanDeWoningOptions" value="{{option | uppercase}}"> {{option}}</mat-option>
      </mat-select>
      <mat-error *ngIf="dataEntryForm.get('de2').get('allowLeavingHome').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de2').get('allowLeavingHome'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="isFilled" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de2').get('allowLeavingHome')) }">
      <mat-label>{{'allowLeavingHome' | translate}}</mat-label>
      <input matInput formControlName="allowLeavingHome" required type="text" [readonly]="isFilled">
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="!isFilled" [class.DCview]="isDCView">
      <mat-label>{{'incapacityReason' | translate}}</mat-label>
      <mat-select required formControlName="incapacityReason" disableOptionCentering>
        <mat-option> </mat-option>
        <mat-option *ngFor="let option of redenArbeidsongeschiktheidOptions" value="{{option | uppercase}}"> {{ option }}</mat-option>
      </mat-select>
      <mat-error *ngIf="dataEntryForm.get('de2').get('incapacityReason').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de2').get('incapacityReason'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="isFilled" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de2').get('incapacityReason')) }">
      <mat-label>{{'incapacityReason' | translate}}</mat-label>
      <input matInput formControlName="incapacityReason" required type="text" [readonly]="isFilled">
    </mat-form-field>

    <div id="hospitalizationDate" [ngClass]="{'errorDate': showErrorHospitalizationDate}" appearance="outline">
      <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de2').get('hospitalizationStart')) }">
        <mat-label>{{'hospitalizationStart' | translate}}</mat-label>
        <input type="text" [textMask]="{mask: datemask}" pattern="^([1-2][0-9]|3[0-1]|0[1-9])\/(0[1-9]|1[0-2])\/(\d{4})$" matInput formControlName="hospitalizationStart" placeholder="dd/mm/yyyy" [readonly]="isFilled">
        <mat-error *ngIf="!isFilled && dataEntryForm.get('de2').get('hospitalizationStart').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de2').get('hospitalizationStart'))}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de2').get('hospitalizationEnd')) }">
        <mat-label>{{'hospitalizationEnd' | translate}}</mat-label>
        <input type="text" [textMask]="{mask: datemask}" pattern="^([1-2][0-9]|3[0-1]|0[1-9])\/(0[1-9]|1[0-2])\/(\d{4})$" matInput formControlName="hospitalizationEnd" placeholder="dd/mm/yyyy" [readonly]="isFilled">
        <mat-error *ngIf="!isFilled && dataEntryForm.get('de2').get('hospitalizationEnd').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de2').get('hospitalizationEnd'))}}</mat-error>
      </mat-form-field>
      <p id="pErrorFont" *ngIf="showErrorHospitalizationDate" class="mat-error ng-star-inserted"> {{errorHospitalizationDateMessage}} </p>
    </div>

    <div id="hospitalizationDate" [ngClass]="{'errorDate': showErrorOccupationalDisabilityDate}" appearance="outline">
      <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de2').get('occupationalDisabilityStart')) }">
        <mat-label>{{'occupationalDisabilityStart' | translate}}</mat-label>
        <input type="text" [textMask]="{mask: datemask}" pattern="^([1-2][0-9]|3[0-1]|0[1-9])\/(0[1-9]|1[0-2])\/(\d{4})$" matInput formControlName="occupationalDisabilityStart" placeholder="dd/mm/yyyy" [readonly]="isFilled">
        <mat-error *ngIf="!isFilled && dataEntryForm.get('de2').get('occupationalDisabilityStart').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de2').get('occupationalDisabilityStart'))}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de2').get('occupationalDisabilityEnd')) }">
        <mat-label>{{'occupationalDisabilityEnd' | translate}}</mat-label>
        <input type="text" [textMask]="{mask: datemask}" pattern="^([1-2][0-9]|3[0-1]|0[1-9])\/(0[1-9]|1[0-2])\/(\d{4})$" matInput formControlName="occupationalDisabilityEnd" placeholder="dd/mm/yyyy" [readonly]="isFilled">
        <mat-error *ngIf="!isFilled && dataEntryForm.get('de2').get('occupationalDisabilityEnd').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de2').get('occupationalDisabilityEnd'))}}</mat-error>
      </mat-form-field>
      <p id="pErrorFont" *ngIf="showErrorOccupationalDisabilityDate" class="mat-error ng-star-inserted"> {{errorOccupationalDisabilityDateMessage}} </p>
    </div>

    <mat-form-field appearance="outline" *ngIf="!isFilled" [class.DCview]="isDCView">
      <mat-label>{{'certificateType' | translate}}</mat-label>
      <mat-select required disableOptionCentering formControlName="certificateType">
        <mat-option> </mat-option>
        <mat-option *ngFor="let option of attestTypeOptions" value="{{option | uppercase}}"> {{ option }}</mat-option>
      </mat-select>
      <mat-error *ngIf="dataEntryForm.get('de2').get('certificateType').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de2').get('certificateType'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="isFilled" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de2').get('certificateType')) }">
      <mat-label>{{'certificateType' | translate}}</mat-label>
      <input matInput formControlName="certificateType" required type="text" [readonly]="isFilled">
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de2').get('certificateDate')) }">
      <mat-label>{{'certificateDate' | translate}}</mat-label>
      <input type="text" [textMask]="{mask: datemask}" pattern="^([1-2][0-9]|3[0-1]|0[1-9])\/(0[1-9]|1[0-2])\/(\d{4})$" matInput formControlName="certificateDate" placeholder="dd/mm/yyyy" [readonly]="isFilled">
      <mat-error *ngIf="!isFilled && dataEntryForm.get('de2').get('certificateDate').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de2').get('certificateDate'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="!isFilled" [class.DCview]="isDCView">
      <mat-label>{{'signature' | translate}}</mat-label>
      <mat-select required disableOptionCentering formControlName="signature">
        <mat-option> </mat-option>
        <mat-option value="YES"> Yes </mat-option>
        <mat-option value="NO"> No </mat-option>
      </mat-select>
      <mat-error *ngIf="dataEntryForm.get('de2').get('signature').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de2').get('signature'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="isFilled" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de2').get('signature')) }">
      <mat-label>{{'signature' | translate}}</mat-label>
      <input matInput formControlName="signature" required type="text" [readonly]="isFilled">
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="!isFilled" [class.DCview]="isDCView">
      <mat-label>{{'stamp' | translate}}</mat-label>
      <mat-select required disableOptionCentering formControlName="stamp">
        <mat-option> </mat-option>
        <mat-option value="YES"> Yes </mat-option>
        <mat-option value="NO"> No </mat-option>
      </mat-select>
      <mat-error *ngIf="dataEntryForm.get('de2').get('stamp').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de2').get('stamp'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="isFilled" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de2').get('stamp')) }">
      <mat-label>{{'stamp' | translate}}</mat-label>
      <input matInput formControlName="stamp" required type="text" [readonly]="isFilled">
    </mat-form-field>

    <div id="hospitalizationDate" [ngClass]="{'errorDate': showErrorTravelAbroadDate}" appearance="outline">
      <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de2').get('travelAbroadStart')) }">
        <mat-label>{{'travelAbroadStart' | translate}}</mat-label>
        <input type="text" [textMask]="{mask: datemask}" pattern="^([1-2][0-9]|3[0-1]|0[1-9])\/(0[1-9]|1[0-2])\/(\d{4})$" matInput formControlName="travelAbroadStart" placeholder="dd/mm/yyyy" [readonly]="isFilled">
        <mat-error *ngIf="!isFilled && dataEntryForm.get('de2').get('travelAbroadStart').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de2').get('travelAbroadStart'))}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de2').get('travelAbroadEnd')) }">
        <mat-label>{{'travelAbroadEnd' | translate}}</mat-label>
        <input type="text" [textMask]="{mask: datemask}" pattern="^([1-2][0-9]|3[0-1]|0[1-9])\/(0[1-9]|1[0-2])\/(\d{4})$" matInput formControlName="travelAbroadEnd" placeholder="dd/mm/yyyy" [readonly]="isFilled">
        <mat-error *ngIf="!isFilled && dataEntryForm.get('de2').get('travelAbroadEnd').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de2').get('travelAbroadEnd'))}}</mat-error>
      </mat-form-field>
      <p id="pErrorFont" *ngIf="showErrorTravelAbroadDate" class="mat-error ng-star-inserted"> {{errorTravelAbroadDateMessage}} </p>
    </div>

    <!-- We need to searched fields in ES -->
    <div id="searchButtonDiv">
      <button type="button" mat-button id="search" (click)="openDialogSearch()" *ngIf="!isFilled"> Search </button>
      <button type="button" mat-button id="search" *ngIf="isFilled" hidden> Search </button>
      <div id="emptySpace" [class.DCview]="isDCView" *ngIf="isFilled"> </div>
    </div>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de2').get('doctorName')) }">
      <mat-label>{{'doctorName' | translate}}</mat-label>
      <input type="text" matInput formControlName="doctorName" [readonly]="isFilled || resultFound || !isSearchDone">
      <mat-error *ngIf="!isFilled && resultFound && dataEntryForm.get('de2').get('doctorName').invalid">{{getErrorSearchMessage(dataEntryForm.get('de2').get('doctorName'))}}</mat-error>
      <mat-error *ngIf="!isFilled && !resultFound && dataEntryForm.get('de2').get('doctorName').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de2').get('doctorName'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de2').get('doctorPhoneNumber')) }">
      <mat-label>{{'doctorPhoneNumber' | translate}}</mat-label>
      <input type="text" matInput formControlName="doctorPhoneNumber" [readonly]="isFilled || resultFound || !isSearchDone">
      <mat-error *ngIf="!isFilled && resultFound && dataEntryForm.get('de2').get('doctorPhoneNumber').invalid">{{getErrorSearchMessage(dataEntryForm.get('de2').get('doctorPhoneNumber'))}}</mat-error>
      <mat-error *ngIf="!isFilled && !resultFound && dataEntryForm.get('de2').get('doctorPhoneNumber').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de2').get('doctorPhoneNumber'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de2').get('rizivNumber')) }">
      <mat-label>{{'rizivNumber' | translate}}</mat-label>
      <input type="text" matInput formControlName="rizivNumber" [readonly]="isFilled || resultFound || !isSearchDone">
      <mat-error *ngIf="!isFilled && resultFound && dataEntryForm.get('de2').get('rizivNumber').invalid">{{getErrorSearchMessage(dataEntryForm.get('de2').get('rizivNumber'))}}</mat-error>
      <mat-error *ngIf="!isFilled && !resultFound && dataEntryForm.get('de2').get('rizivNumber').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de2').get('rizivNumber'))}}</mat-error>
    </mat-form-field>



  </div>

  <mat-divider *ngIf="showDE2"></mat-divider>

  <div id="de3" *ngIf="showDE3" formGroupName="de3">
    <h3> Data Entry Process 3</h3>

    <!-- We need to search this fields in ES -->

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de3').get('patientStreet')) }">
      <mat-label>{{'patientStreet' | translate}}</mat-label>
      <input type="text" matInput formControlName="patientStreet" required [readonly]="isFilled">
      <mat-error *ngIf="!isFilled && dataEntryForm.get('de3').get('patientStreet').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de3').get('patientStreet'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de3').get('patientHouseNumber')) }">
      <mat-label>{{'patientHouseNumber' | translate}}</mat-label>
      <input type="text" matInput formControlName="patientHouseNumber" required [readonly]="isFilled">
      <mat-error *ngIf="!isFilled && dataEntryForm.get('de3').get('patientHouseNumber').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de3').get('patientHouseNumber'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de3').get('patientBusNumber')) }">
      <mat-label>{{'patientBusNumber' | translate}}</mat-label>
      <input type="text" matInput formControlName="patientBusNumber" [readonly]="isFilled">
      <!-- <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>-->
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de3').get('patientPostalNumber')) }">
      <mat-label>{{'patientPostalNumber' | translate}}</mat-label>
      <input type="text" matInput formControlName="patientPostalNumber" [readonly]="isFilled">
      <!--  <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>-->
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de3').get('patientCity')) }">
      <mat-label>{{'patientCity' | translate}}</mat-label>
      <input type="text" matInput formControlName="patientCity" required [readonly]="isFilled">
      <mat-error *ngIf="!isFilled && dataEntryForm.get('de3').get('patientCity').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de3').get('patientCity'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="!isFilled" [class.DCview]="isDCView">
      <mat-label>{{'patientCountryCode' | translate}}</mat-label>
      <mat-select required disableOptionCentering formControlName="patientCountryCode">
        <mat-option> </mat-option>
        <mat-option *ngFor="let option of verblijfplaatsLandcodeOptions" value="{{option | uppercase}}"> {{ option }}</mat-option>
      </mat-select>
      <mat-error *ngIf="dataEntryForm.get('de3').get('patientCountryCode').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de3').get('patientCountryCode'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="isFilled" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de3').get('patientCountryCode')) }">
      <mat-label>{{'patientCountryCode' | translate}}</mat-label>
      <input type="text" matInput formControlName="patientCountryCode" [readonly]="isFilled">
    </mat-form-field>

    <!-- END -->

  </div>

  <mat-divider *ngIf="showDE3"></mat-divider>

  <div id="de4" *ngIf="showDE4" formGroupName="de4">
    <h3> Data Entry Process 4</h3>
    <div id="hospitalizationDate" [ngClass]="{'errorDate': showErrorPerformanceDecreaseDate}" appearance="outline">
      <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de4').get('performanceDecreaseStart')) }">
        <mat-label>{{'performanceDecreaseStart' | translate}}</mat-label>
        <input type="text" [textMask]="{mask: datemask}" pattern="^([1-2][0-9]|3[0-1]|0[1-9])\/(0[1-9]|1[0-2])\/(\d{4})$" matInput formControlName="performanceDecreaseStart" placeholder="dd/mm/yyyy" [readonly]="isFilled">
        <mat-error *ngIf="!isFilled && dataEntryForm.get('de4').get('performanceDecreaseStart').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de4').get('performanceDecreaseStart'))}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de4').get('performanceDecreaseEnd')) }">
        <mat-label>{{'performanceDecreaseEnd' | translate}}</mat-label>
        <input type="text" [textMask]="{mask: datemask}" pattern="^([1-2][0-9]|3[0-1]|0[1-9])\/(0[1-9]|1[0-2])\/(\d{4})$" matInput formControlName="performanceDecreaseEnd" placeholder="dd/mm/yyyy" [readonly]="isFilled">
        <mat-error *ngIf="!isFilled && dataEntryForm.get('de4').get('performanceDecreaseEnd').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de4').get('performanceDecreaseEnd'))}}</mat-error>
      </mat-form-field>
      <p id="pErrorFont" *ngIf="showErrorPerformanceDecreaseDate" class="mat-error ng-star-inserted"> {{errorPerformanceDecreaseDateMessage}} </p>
    </div>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de4').get('performanceDecreasePercentage')) }">
      <mat-label>{{'performanceDecreasePercentage' | translate}}</mat-label>
      <input type="text" max="100" min="0" matInput formControlName="performanceDecreasePercentage" pattern="(0?[0-9]?[0-9]|100)" required [readonly]="isFilled">
      <mat-error *ngIf="!isFilled && dataEntryForm.get('de4').get('performanceDecreasePercentage').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de4').get('performanceDecreasePercentage'))}}</mat-error>
      <mat-error *ngIf="!isFilled && dataEntryForm.get('de4').get('performanceDecreasePercentage').invalid">{{getErrorPercentage()}}</mat-error>
    </mat-form-field>

  </div>

  <mat-divider *ngIf="showDE4"></mat-divider>

  <div id="de5" *ngIf="showDE5" formGroupName="de5">
    <h3> Data Entry Process 5</h3>

    <mat-form-field appearance="outline" *ngIf="!isFilled" [class.DCview]="isDCView">
      <mat-label>Firm Type</mat-label>
      <mat-select required disableOptionCentering formControlName="companyName">
        <mat-option> </mat-option>
        <mat-option *ngFor="let option of firmanaamOptions" value="{{option | uppercase}}"> {{ option }}</mat-option>
      </mat-select>
      <mat-error *ngIf="dataEntryForm.get('de5').get('companyName').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de5').get('companyName'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="isFilled" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de5').get('companyName')) }">
      <mat-label>Firm Type</mat-label>
      <input type="text" matInput formControlName="companyName" [readonly]="isFilled">
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de5').get('companyMensuraId')) }">
      <mat-label>Firm Mensura ID</mat-label>
      <input type="text" matInput formControlName="companyMensuraId" [readonly]="isFilled">
      <!--<mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>-->
    </mat-form-field>

    <!-- We need to get this fields value -->

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de5').get('resultDoctorId')) }">
      <mat-label>Doctor ID</mat-label>
      <input type="text" matInput formControlName="resultDoctorId" readonly>
      <!--<mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>-->
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de5').get('resultDoctorFirstName')) }">
      <mat-label>Doctor First Name</mat-label>
      <input type="text" matInput formControlName="resultDoctorFirstName" readonly>
      <!--<mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>-->
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de5').get('resultDoctorLastName')) }">
      <mat-label>Doctor Last Name</mat-label>
      <input type="text" matInput formControlName="resultDoctorLastName" readonly>
      <!--<mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>-->
    </mat-form-field>

    <!-- END -->

    <mat-form-field appearance="outline" *ngIf="!isFilled" [class.DCview]="isDCView">
      <mat-label>Result Location</mat-label>
      <mat-select required disableOptionCentering formControlName="resultCheck">
        <mat-option> </mat-option>
        <mat-option *ngFor="let option of resultaatControleOptions" value="{{option | uppercase}}"> {{ option }}</mat-option>
      </mat-select>
      <mat-error *ngIf="dataEntryForm.get('de5').get('resultCheck').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de5').get('resultCheck'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="isFilled" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de5').get('resultCheck')) }">
      <mat-label>Result Location</mat-label>
      <input type="text" matInput formControlName="resultCheck" [readonly]="isFilled">
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de5').get('resultDate')) }">
      <mat-label>Result Date</mat-label>
      <input type="text" [textMask]="{mask: datemask}" pattern="^([1-2][0-9]|3[0-1]|0[1-9])\/(0[1-9]|1[0-2])\/(\d{4})$" matInput formControlName="resultDate" placeholder="dd/mm/yyyy" [readonly]="isFilled">
      <mat-error *ngIf="!isFilled && dataEntryForm.get('de5').get('resultDate').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de5').get('resultDate'))}}</mat-error>
      <mat-error *ngIf="!isFilled && dataEntryForm.get('de5').get('resultDate').invalid">{{getErrorResultDate(dataEntryForm.get('de5').get('resultDate'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de5').get('resultTime')) }">
      <mat-label>Result Hour</mat-label>
      <input type="text" [textMask]="{mask: timeMask}" pattern="^(0[89]|1[0-9]|20):([0-5][0-9])|21:00$" matInput formControlName="resultTime" placeholder="hh:mm" [readonly]="isFilled">
      <mat-error *ngIf="!isFilled && dataEntryForm.get('de5').get('resultTime').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de5').get('resultTime'))}}</mat-error>
    </mat-form-field>

  </div>

  <mat-divider *ngIf="showDE5"></mat-divider>

  <div id="de6" *ngIf="showDE6" formGroupName="de6">
    <h3> Data Entry Process 6</h3>

    <div id="hospitalizationDate" [ngClass]="{'errorDate': showErrorTemporaryResidenceDate}" appearance="outline">
      <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de6').get('temporaryResidenceStart')) }">
        <mat-label>{{'temporaryResidenceStart' | translate}}</mat-label>
        <input type="text" [textMask]="{mask: datemask}" pattern="^([1-2][0-9]|3[0-1]|0[1-9])\/(0[1-9]|1[0-2])\/(\d{4})$" matInput formControlName="temporaryResidenceStart" placeholder="dd/mm/yyyy" [readonly]="isFilled">
        <mat-error *ngIf="!isFilled && dataEntryForm.get('de6').get('temporaryResidenceStart').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de6').get('temporaryResidenceStart'))}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de6').get('temporaryResidenceEnd')) }">
        <mat-label>{{'temporaryResidenceEnd' | translate}}</mat-label>
        <input type="text" [textMask]="{mask: datemask}" pattern="^([1-2][0-9]|3[0-1]|0[1-9])\/(0[1-9]|1[0-2])\/(\d{4})$" matInput formControlName="temporaryResidenceEnd" placeholder="dd/mm/yyyy" [readonly]="isFilled">
        <mat-error *ngIf="!isFilled && dataEntryForm.get('de6').get('temporaryResidenceEnd').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de6').get('temporaryResidenceEnd'))}}</mat-error>
      </mat-form-field>
      <p id="pErrorFont" *ngIf="showErrorTemporaryResidenceDate" class="mat-error ng-star-inserted"> {{errorTemporaryResidenceDateMessage}} </p>
    </div>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de6').get('temporaryResidenceName')) }">
      <mat-label>{{'temporaryResidenceName' | translate}}</mat-label>
      <input type="text" matInput formControlName="temporaryResidenceName" [readonly]="isFilled">
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de6').get('temporaryResidenceStreet')) }">
      <mat-label>{{'temporaryResidenceStreet' | translate}}</mat-label>
      <input type="text" matInput formControlName="temporaryResidenceStreet" [readonly]="isFilled">
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de6').get('temporaryResidenceHouseNumber')) }">
      <mat-label>{{'temporaryResidenceHouseNumber' | translate}}</mat-label>
      <input type="text" matInput formControlName="temporaryResidenceHouseNumber" [readonly]="isFilled">
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de6').get('temporaryResidenceBusNumber')) }">
      <mat-label>{{'temporaryResidenceBusNumber' | translate}}</mat-label>
      <input type="text" matInput formControlName="temporaryResidenceBusNumber" [readonly]="isFilled">
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de6').get('temporaryResidencePostalNumber')) }">
      <mat-label>{{'temporaryResidencePostalNumber' | translate}}</mat-label>
      <input type="text" matInput formControlName="temporaryResidencePostalNumber" [readonly]="isFilled">
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de6').get('temporaryResidenceCity')) }">
      <mat-label>{{'temporaryResidenceCity' | translate}}</mat-label>
      <input type="text" matInput formControlName="temporaryResidenceCity" [readonly]="isFilled">
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="!isFilled" [class.DCview]="isDCView">
      <mat-label>{{'temporaryResidenceCountryCode' | translate}}</mat-label>
      <mat-select required disableOptionCentering formControlName="temporaryResidenceCountryCode">
        <mat-option> </mat-option>
        <mat-option *ngFor="let option of verblijfplaatsLandcodeOptions" value="{{option | uppercase}}"> {{ option }}</mat-option>
      </mat-select>
      <mat-error *ngIf="dataEntryForm.get('de6').get('temporaryResidenceCountryCode').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de6').get('temporaryResidenceCountryCode'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="isFilled" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de6').get('temporaryResidenceCountryCode')) }">
      <mat-label>{{'temporaryResidenceCountryCode' | translate}}</mat-label>
      <mat-label>Tijdelijke verblijfplaats landcode</mat-label>
      <input type="text" matInput formControlName="temporaryResidenceCountryCode" [readonly]="isFilled">
    </mat-form-field>

  </div>

  <mat-divider *ngIf="showDE6"></mat-divider>

  <div id="de7" *ngIf="showDE7" formGroupName="de7">
    <h3> Data Entry Process 7</h3>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('de7').get('companyName')) }">
      <mat-label>Firm Name</mat-label>
      <input required type="text" matInput formControlName="companyName" [readonly]="isFilled">
      <mat-error *ngIf="dataEntryForm.get('de7').get('companyName').invalid">{{getErrorRequiredMessage(dataEntryForm.get('de7').get('companyName'))}}</mat-error>
    </mat-form-field>

  </div>

  <mat-divider *ngIf="showDE7"></mat-divider>

  <div id="deMOD" *ngIf="showMensuraOneDay" formGroupName="deMOD">
    <h3> Mensura One Day</h3>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('deMOD').get('companyMensuraId')) }">
      <mat-label>Firm Mensura ID</mat-label>
      <input type="text" matInput formControlName="companyMensuraId" [readonly]="isFilled || resultFound || !isSearchDone">
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('deMOD').get('personnelNumber')) }">
      <mat-label>{{'personnelNumber' | translate}}</mat-label>
      <input type="text" pattern="[0-9]{11}" minlength="11" maxlength="11" matInput formControlName="personnelNumber" [readonly]="isFilled || resultFound || !isSearchDone">
      <mat-error *ngIf="!isFilled && dataEntryForm.get('deMOD').get('personnelNumber').invalid">{{getErrorFirmPatientIDValidation(dataEntryForm.get('deMOD').get('personnelNumber'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('deMOD').get('occupationalDisabilityStart')) }">
      <mat-label>{{'occupationalDisabilityStart' | translate}}</mat-label>
      <input type="text" [textMask]="{mask: datemask}" placeholder="dd/mm/yyyy" pattern="^([1-2][0-9]|3[0-1]|0[1-9])\/(0[1-9]|1[0-2])\/(\d{4})$" matInput formControlName="occupationalDisabilityStart" required [readonly]="isFilled">
      <mat-error *ngIf="!isFilled && dataEntryForm.get('deMOD').get('occupationalDisabilityStart').invalid">{{getErrorRequiredMessage(dataEntryForm.get('deMOD').get('occupationalDisabilityStart'))}}</mat-error>
    </mat-form-field>

    <div id="searchButtonDiv">
      <button type="button" mat-button id="search" (click)="openDialogMensuraSearch()" *ngIf="!isFilled"> Search </button>
      <button type="button" mat-button id="search" *ngIf="isFilled" hidden> Search </button>
      <div id="emptySpace" [class.DCview]="isDCView" *ngIf="isFilled"> </div>
    </div>

  </div>

  <mat-divider *ngIf="showMensuraOneDay"></mat-divider>

  <div id="deUnk" *ngIf="showUnknown" formGroupName="deUnk">
    <h3>Unknown Document Type</h3>

    <mat-form-field appearance="outline" *ngIf="!isFilled" [class.DCview]="isDCView">
      <mat-label>Document Type</mat-label>
      <mat-select required disableOptionCentering formControlName="documentType">
        <mat-option> </mat-option>
        <mat-option *ngFor="let option of documentTypeOptions" value="{{option | uppercase}}"> {{ option }}</mat-option>
      </mat-select>
      <mat-error *ngIf="dataEntryForm.get('deUnk').get('documentType').invalid">{{getErrorRequiredMessage(dataEntryForm.get('deUnk').get('documentType'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="isFilled" [ngClass]="{'DCview':isDCView, 'correctFieldBG correctFieldL correctFieldB': isCorrectField(dataEntryForm.get('deUnk').get('documentType')) }">
      <mat-label>Document Type</mat-label>
      <input type="text" matInput formControlName="documentType" [readonly]="isFilled">
    </mat-form-field>

  </div>

  <mat-divider *ngIf="showUnknown"></mat-divider>

  <div id="buttons" *ngIf="!dataEntryInfo">
    <button mat-raised-button id="save" type="submit" [class.DCview]="isDCView" [disabled]="disableSend"> Save </button>
    <button mat-raised-button type="button" id="close" (click)="close()" [class.DCview]="isDCView" [disabled]="disableClose"> Close </button>
    <button mat-raised-button type="button" id="block" [class.DCview]="isDCView" (click)="block()" [disabled]="disableBlock"> Block </button>
  </div>

</form>
  

