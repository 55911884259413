import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FileServiceService } from './file-service.service';

@Injectable({
  providedIn: 'root'
})
export class IdentificationControllerService {

  apiUrl: string = environment.backendURL;
  constructor(private http: HttpClient, private fileServiceService: FileServiceService) { }

  async SendForm(identificationform, username, folderHash, project, startDateTime, status) {
    const url = this.apiUrl + '/IdentificationApi';

    //const headers = new HttpHeaders().set("Access-Control-Allow-Origin", "*");
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return await this.http.post(url, {
      "identification": identificationform["identification"],
      "label": identificationform["label"],
      "username": username,
      "project": project,
      "folderHash": folderHash,
      "startDateTime": startDateTime,
      "status": status
    }, { headers }).toPromise().then(data => {
      console.log("POST Request is successful ", data);
      return "The form has been filled susccessfully.";
    })
      .catch(error => {
        console.log(error);
        return "A problem has occurred.";
      })
  }


}
