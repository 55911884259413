<cdk-virtual-scroll-viewport itemSize="40" id="viewportDE">
	<div *ngIf="!existChild">
		<p id="noData"> No data </p>
	</div>
	<div *ngIf="existChild">
		<div fxFlex="50" id="containerForm" *ngFor="let dataEntry of dataEntries; let i = index">
			<div fxFlex="97">
				<p class="title"> {{titleForDataEntry[i]}}: {{ userNamesFromDataEntries[i] }} </p>
				<app-gh-production-data-entry-viewer [fieldsComparationStyle]="dataEntryStyle" [dataEntryInfo]="dataEntry" [startDateTime]="startDateTime" [commonInfo]="commonInfo"></app-gh-production-data-entry-viewer>
			</div>
			<mat-divider fxFlex="3" vertical inset id="verticalDivider"></mat-divider>
		</div>
	</div>
</cdk-virtual-scroll-viewport>
