<div id="modal">
  <h1 mat-dialog-title> Block {{process}} </h1>
  <div mat-dialog-content id="content">

    <form id="form" [formGroup]="block" (ngSubmit)="onSubmit(block.value)" autocomplete="off">
      <mat-form-field appearance="legacy">
        <mat-label>Reason</mat-label>
        <input type="text" matInput formControlName="reason">
      </mat-form-field>

      <mat-form-field appearance="legacy">
        <mat-label>Remarks</mat-label>
        <input type="text" matInput formControlName="remarks">
      </mat-form-field>

      <button mat-raised-button id="send" type="submit"> Send </button>

    </form>
    
  </div>

</div>
