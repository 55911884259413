<form #deform id="idForm" [formGroup]="identificationform" (ngSubmit)="onSubmit(identificationform.value)" autocomplete="off">

  <div id="de1" layout="column">
    <h3> Identification </h3>

    <mat-form-field appearance="outline">
      <mat-label>Policy Number</mat-label>
      <input matInput formControlName="identification">
      <mat-error *ngIf="identificationform.get('identification').invalid">{{getErrorRequiredMessage(identificationform.get('identification'))}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" >
      <mat-label>Select</mat-label>
      <mat-select required disableOptionCentering formControlName="label">
        <mat-option> </mat-option>
        <mat-option *ngFor="let option of selectOptions" value="{{option}}"> {{option}}</mat-option>
      </mat-select>
      <mat-error *ngIf="identificationform.get('label').invalid">{{getErrorRequiredMessage(identificationform.get('label'))}}</mat-error>
    </mat-form-field>

  </div>

  <mat-divider ></mat-divider>

  <div id="buttons" >
    <button mat-raised-button id="save" type="submit" [disabled]="disableSend"> Save </button>
    <button mat-raised-button type="button" id="close" (click)="close()" [disabled]="disableClose"> Close </button>
    <button mat-raised-button type="button" id="block" (click)="block()" [disabled]="disableBlock"> Block </button>
  </div>

</form>
