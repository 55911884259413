import { Component, OnInit, Input } from '@angular/core';
import { Dictionary } from '../Interfaces/dictionary';
import { DataEntryControllerService } from '../services/data-entry-controller.service';
import { rentaDCInfo, rentaModel, clientRenta, requesterRenta, documentRenta } from '../Interfaces/rentaDCInfo';

@Component({
  selector: 'app-renta-data-conflict-viewer',
  templateUrl: './renta-data-conflict-viewer.component.html',
  styleUrls: ['./renta-data-conflict-viewer.component.css']
})
export class RentaDataConflictViewerComponent implements OnInit {

  constructor(private dataEntryControllerService: DataEntryControllerService) {

  }
  @Input('startDateTime') startDateTime: Date;

  nchild: Array<Dictionary> = new Array<Dictionary>();
  heightWindow: string; //We use that for stablishing the height in the scroll.
  folderHash: string;
  dataEntries: Array<rentaDCInfo> = new Array<rentaDCInfo>();


  ngOnInit() {

    var url = new URL(window.location.href);
    this.folderHash = url.searchParams.get("folderHash");
    this.startInfo();
    var toolbarHeight = (document.getElementsByClassName("mat-toolbar-row").item(0) as HTMLElement).offsetHeight; 
    this.heightWindow = document.documentElement.clientHeight - toolbarHeight + "px";
    console.log(this.heightWindow);
    document.getElementById("viewportDE").style.height = this.heightWindow;

    if (document.getElementById("verticalDivider"))
      document.getElementById("verticalDivider").style.height = document.getElementById("containerForm").style.height;

  }


  async startInfo() {
    var dataEntriesModel = await this.dataEntryControllerService.ObtainDEModel(this.folderHash, "RENTA", false) as rentaModel[];

    var clientWrong = this.failsClient(dataEntriesModel[0].Client, dataEntriesModel[1].Client);
    var requesterWrong = this.failsRequester(dataEntriesModel[0].Requester, dataEntriesModel[1].Requester);
    var documentWrong = this.failsDocument(dataEntriesModel[0].Document, dataEntriesModel[1].Document);

    this.dataEntries.push(
      {
        dataEntryValue: dataEntriesModel[0],
        clientWrong: clientWrong,
        requesterWrong: requesterWrong,
        documentWrong: documentWrong
      },
      {
        dataEntryValue: dataEntriesModel[1],
        clientWrong: clientWrong,
        requesterWrong: requesterWrong,
        documentWrong: documentWrong
      },
    );

    console.log(this.dataEntries);

    this.nchild = new Array<Dictionary>(
      { key: "de1", value: JSON.stringify(this.dataEntries[0]) },
      { key: "de2", value: JSON.stringify(this.dataEntries[1]) },
      { key: "dc", value: "" }
    )

  }

  isDc(id: string, containerForm: boolean): string {
    if (containerForm) {
      return "33";
    }
    else {
      if (id != 'dc') {
        return "97";
      }
      else
        return "100";
    }
    
  }



  failsClient(de1: clientRenta, de2: clientRenta): string {
    var partFails = "";

    if (de1.LicensePlate == de2.LicensePlate)
      partFails += 0;
    else
      partFails += 1;

    if (de1.ClientID == de2.ClientID)
      partFails += 0;
    else
      partFails += 1;

    if (de1.ClientName == de2.ClientName)
      partFails += 0;
    else
      partFails += 1;

    return partFails;
  }

  failsRequester(de1: requesterRenta, de2: requesterRenta): string {
    var partFails = "";

    if (de1.RequesterType == de2.RequesterType)
      partFails += 0;
    else
      partFails += 1;

    if (de1.RequesterName == de2.RequesterName)
      partFails += 0;
    else
      partFails += 1;

    if (de1.RequesterAddress == de2.RequesterAddress)
      partFails += 0;
    else
      partFails += 1;

    if (de1.RequesterZipCode == de2.RequesterZipCode)
      partFails += 0;
    else
      partFails += 1;

    if (de1.RequesterCity == de2.RequesterCity)
      partFails += 0;
    else
      partFails += 1;

    if (de1.RequesterCountry == de2.RequesterCountry)
      partFails += 0;
    else
      partFails += 1;

    if (de1.RequesterEmail == de2.RequesterEmail)
      partFails += 0;
    else
      partFails += 1;

    if (de1.RequesterPhone == de2.RequesterPhone)
      partFails += 0;
    else
      partFails += 1;

    if (de1.RequesterFax == de2.RequesterFax)
      partFails += 0;
    else
      partFails += 1;

    return partFails;

  }

  failsDocument(de1: documentRenta, de2: documentRenta): string {
    var partFails = "";

    if (de1.DocumentType == de2.DocumentType)
      partFails += 0;
    else
      partFails += 1;

    if (de1.SubType == de2.SubType)
      partFails += 0;
    else
      partFails += 1;

    if (de1.DocumentReference == de2.DocumentReference)
      partFails += 0;
    else
      partFails += 1;

    if (de1.DocumentDate == de2.DocumentDate)
      partFails += 0;
    else
      partFails += 1;

    if (de1.InfractionDate == de2.InfractionDate)
      partFails += 0;
    else
      partFails += 1;

    if (de1.DeadlineDate == de2.DeadlineDate)
      partFails += 0;
    else
      partFails += 1;

    if (de1.DaysPayment == de2.DaysPayment)
      partFails += 0;
    else
      partFails += 1;

    if (de1.Amount == de2.Amount)
      partFails += 0;
    else
      partFails += 1;

    if (de1.StatusReason == de2.StatusReason)
      partFails += 0;
    else
      partFails += 1;

    return partFails;

  }

}
